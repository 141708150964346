import { FC, useEffect, useState } from 'react'
import { Button, Checkbox, Grid, useToast } from '@aurecon-creative-technologies/styleguide'
import { ResponseData } from '../../models/api/IResponse'
import { IDeltamap, IDeltamapCreate } from '../../models/IDeltamapModel'
import { saveDeltamap } from '../../api/DeltamapService'
import TextBox from '../common/TextBox'
import { DeltamapValidator, DeltamapFields } from '../../helpers/deltamapFormValidator'
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'

import { ClientLookup, DeltamapDetails, LandscapeLookup, MarketLookup, NewDeltamapId } from '../../stores/AppStore'
import ConfirmModal from '../common/ConfirmModal'
import { ILookup } from '../../models/api/ILookup'
import LookupBox from '../common/LookupBox'
import InputLabel from '../common/InputLabel'
import { dateToString } from '../../helpers/utils'
import LensDropdown from '../common/LensDropdown'

import Style from '../../styles/DeltamapForm.module.sass'

interface IDeltamapFormProps {
  deltamap: IDeltamap | null
  onGoBack?: (refresh: boolean) => void
}

const DeltamapForm: FC<IDeltamapFormProps> = (props) => {
  const { user } = useAuth0()
  const { onGoBack } = props
  const { addToast } = useToast()
  const [deltamap, setDeltamap] = useState<IDeltamapCreate>({})
  const [saving, setSaving] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [formDirty, setFormDirty] = useState(false)
  const [errors, setErrors] = useState({})
  const loadableClients = useRecoilValueLoadable(ClientLookup)
  const loadableMarkets = useRecoilValueLoadable(MarketLookup)
  const loadableLandscapes = useRecoilValueLoadable(LandscapeLookup)
  const setNewDeltamapId = useSetRecoilState(NewDeltamapId)
  const refreshDeltamap = useRecoilRefresher_UNSTABLE(DeltamapDetails)
  const refreshClients = useRecoilRefresher_UNSTABLE(ClientLookup)
  const refreshMarkets = useRecoilRefresher_UNSTABLE(MarketLookup)
  const refreshLandscapes = useRecoilRefresher_UNSTABLE(LandscapeLookup)

  const [clients, setClients] = useState<ILookup[]>()
  const [markets, setMarkets] = useState<ILookup[]>()
  const [landscapes, setLandscapes] = useState<ILookup[]>()
  const [client, setClient] = useState<string>()
  const [market, setMarket] = useState<string>()
  const [landscape, setLandscape] = useState<string>()

  useEffect(() => {
    if (loadableClients.state !== 'hasValue' || !loadableClients.contents) return
    setClients(loadableClients.contents.map((lookup) => ({ ...lookup })))
  }, [loadableClients.contents, loadableClients.state])
  useEffect(() => {
    if (loadableMarkets.state !== 'hasValue' || !loadableMarkets.contents) return
    setMarkets(loadableMarkets.contents.map((lookup) => ({ ...lookup })))
  }, [loadableMarkets.contents, loadableMarkets.state])
  useEffect(() => {
    if (loadableLandscapes.state !== 'hasValue' || !loadableLandscapes.contents) return
    setLandscapes(loadableLandscapes.contents.map((lookup) => ({ ...lookup })))
  }, [loadableLandscapes.contents, loadableLandscapes.state])

  const selectLookup = (type: 'clientId' | 'landscapeId' | 'marketId', value: ILookup) => {
    if (value.id === -1) {
      switch (type) {
        case 'landscapeId':
          setLandscapes((current) => (current ? [...current.filter((c) => c.id !== -1), value] : [value]))
          setLandscape(value.title)
          break
        case 'clientId':
          setClients((current) => (current ? [...current.filter((c) => c.id !== -1), value] : [value]))
          setClient(value.title)
          break
        case 'marketId':
          setMarkets((current) => (current ? [...current.filter((c) => c.id !== -1), value] : [value]))
          setMarket(value.title)
          break

        default:
          break
      }
    }
    onFormInputChanged(type, value.id)
  }

  useEffect(() => {
    if (props.deltamap) {
      setDeltamap(props.deltamap)
      return
    }
  }, [props.deltamap])

  const validateForm = (newDeltamap: IDeltamapCreate) => {
    const errorMessage = { ...errors }
    Object.keys(DeltamapValidator).forEach((key) => (errorMessage[key] = DeltamapValidator[key](newDeltamap[key])))
    return errorMessage
  }

  const onFormInputChanged = (fieldName: string, value: string | number | boolean) => {
    const newDeltamap = { ...deltamap, [fieldName]: value }
    setErrors({ ...validateForm(newDeltamap) })
    setDeltamap(newDeltamap)
    setFormDirty(true)
  }

  const onSave = async () => {
    setSaving(true)

    const response = await saveDeltamap({
      id: deltamap.id,
      title: deltamap.title,
      clientId: deltamap.clientId,
      marketId: deltamap.marketId,
      landscapeId: deltamap.landscapeId,
      lensId: deltamap.lensId,
      internal: !!deltamap.internal,
      market,
      landscape,
      client,
    })
    setSaving(false)

    if (!response || (response && response.success === false)) {
      addToast({
        type: 'error',
        message: 'Deltamap can not be created/updated. Please try again later',
        timeout: 5000,
      })
      return
    }

    const newDeltamap = ResponseData(response) as IDeltamap
    addToast({
      type: 'success',
      message: props.deltamap
        ? `Deltamap ${newDeltamap.title} has been successfully updated`
        : `A New Deltamap ${newDeltamap.title} has been created/updated successfully`,
      timeout: 5000,
    })

    setNewDeltamapId(newDeltamap.id || 0)
    refreshDeltamap()
    refreshClients()
    refreshLandscapes()
    refreshMarkets()
    if (onGoBack) {
      onGoBack(true)
      return
    }

    window.location.hash = `/deltamap/${newDeltamap.id}/2`
  }

  const onCancel = () => {
    if (onGoBack) {
      onGoBack(false)
      return
    }
    window.location.hash = '/'
  }

  const disabledSave =
    !formDirty ||
    (errors &&
      (errors[DeltamapFields.clientId] ||
        errors[DeltamapFields.clientId] ||
        errors[DeltamapFields.landscapeId] ||
        errors[DeltamapFields.marketId] ||
        errors[DeltamapFields.title]))

  return (
    <div className={Style.deltamapContainer}>
      <Grid row cssClass={Style.heading}>
        <Grid cell item xs={12}>
          <h2>{!deltamap.id ? 'Create' : 'Edit'}</h2>
        </Grid>
      </Grid>

      <Grid row xs={12} gap={12} cssClass={Style.deltamapForm}>
        <Grid item xs={12}>
          <TextBox
            label='Deltamap Name'
            placeholder='Enter Deltamap Name here'
            error={errors[DeltamapFields.title]}
            required={true}
            value={deltamap.title || ''}
            onChange={(value: string) => onFormInputChanged(DeltamapFields.title, value)}
            limit={255}
          />
        </Grid>
        <Grid item xs={6}>
          <LookupBox
            label='Client'
            lookupItems={clients || []}
            displaySelectedItem
            selectedId={deltamap.clientId}
            onSelectedItem={(item) => selectLookup('clientId', item)}
            allowNewItem
            minCharsBeforeOpen={0}
            cssClass={Style.fixed}
            placeholder='Enter Client Name Here'
            required={true}
          />
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', alignContent: 'flex-start' }}>
          <InputLabel label='Default Lens' required />

          <LensDropdown lensId={deltamap.lensId} onSelected={(id) => onFormInputChanged('lensId', id)} />
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            checked={deltamap.internal || false}
            label='Aurecon internal'
            onChange={(b) => onFormInputChanged('internal', b)}
          />
        </Grid>
        <Grid item xs={6}>
          <LookupBox
            label='Market'
            lookupItems={markets || []}
            displaySelectedItem
            selectedId={deltamap.marketId}
            onSelectedItem={(item) => selectLookup('marketId', item)}
            allowNewItem
            minCharsBeforeOpen={0}
            cssClass={Style.fixed}
            placeholder='Enter Market Name Here'
            required={true}
          />
        </Grid>
        <Grid item xs={6}>
          <LookupBox
            label='Landscape'
            lookupItems={landscapes || []}
            displaySelectedItem
            selectedId={deltamap.landscapeId}
            onSelectedItem={(item) => selectLookup('landscapeId', item)}
            allowNewItem
            minCharsBeforeOpen={0}
            cssClass={Style.fixed}
            placeholder='Enter Landscape Name Here'
            required={true}
          />
        </Grid>
        <div style={{ flex: '1', flexDirection: 'column' }}></div>
        <Grid item xs={12} sm={deltamap.createdAt ? 6 : 12}>
          <InputLabel label='Deltamap Creator' />
          <span>{deltamap.createdBy || user?.name}</span>
        </Grid>
        {deltamap.createdAt && (
          <Grid item xs={12} sm={6}>
            <InputLabel label='Created Date' />
            <span>{dateToString(deltamap.createdAt)}</span>
          </Grid>
        )}
      </Grid>
      <div className={Style.actionButtons}>
        <Button
          label='Cancel'
          type='secondary'
          disabled={saving}
          onClick={() => {
            !formDirty && onCancel()
            setOpenModal(true)
          }}
        />
        <Button label='Save' type='primary' disabled={disabledSave || saving} onClick={onSave} loading={saving} />
      </div>
      <ConfirmModal
        open={openModal}
        onYes={onCancel}
        onClose={() => setOpenModal(false)}
        title={`Cancel ${!props.deltamap ? 'Creating' : 'Editing'} Deltamap?`}
        message={`Canceling ${
          !props.deltamap ? 'Create' : 'Edit'
        } Deltamap can not be undone, all the data within the task will lost.`}
      />
    </div>
  )
}

export default DeltamapForm
