import { FC, useEffect, useState } from 'react'
import { Button, Grid, useToast } from '@aurecon-creative-technologies/styleguide'
import { ResponseData } from '../../models/api/IResponse'
import { ILens, ILensCreate } from '../../models/ILensModel'
import { saveLens } from '../../api/LensService'
import TextBox from '../common/TextBox'
import { LensValidator, LensFields } from '../../helpers/deltamapFormValidator'
import { useRecoilRefresher_UNSTABLE, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'

import { LensDetails, LensLookup, NewLensId } from '../../stores/AppStore'
import ConfirmModal from '../common/ConfirmModal'
import InputLabel from '../common/InputLabel'
import { dateToString } from '../../helpers/utils'

import Uploader from '../common/Uploader'
import { getMediaBlobUrl } from '../../api/StorageService'
import { LENS_CONTAINER } from '../../config/config'

import Style from '../../styles/LensForm.module.sass'

interface ILensFormProps {
  lens: ILens | null
  onGoBack?: (refresh: boolean) => void
}

const LensForm: FC<ILensFormProps> = (props) => {
  const { user } = useAuth0()
  const { onGoBack } = props
  const { addToast } = useToast()
  const [lens, setLens] = useState<ILensCreate>({})
  const [saving, setSaving] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [formDirty, setFormDirty] = useState(false)
  const [errors, setErrors] = useState({})
  const setNewLensId = useSetRecoilState(NewLensId)
  const refreshLensList = useRecoilRefresher_UNSTABLE(LensLookup)
  const refreshLensDetails = useRecoilRefresher_UNSTABLE(LensDetails)

  const [lensFile, setLensFile] = useState<File[]>([])
  const [lensUrl, setLensUrl] = useState('')

  useEffect(() => {
    if (props.lens) {
      setLens(props.lens)
      return
    }
  }, [props.lens])

  const onLensFileChange = (lensFiles: File[], fileError?: string) => {
    if (!lensFiles.length) {
      // clear file
      const newLens = { ...lens, fileName: '', fileUrl: undefined, blobName: '' }
      const errorMessage = validateForm(newLens)
      setLensUrl('')
      setLens(newLens)
      setErrors({ ...errorMessage, lensFile: fileError || '' })
    } else {
      const newLens = { ...lens, fileName: lensFiles[0]?.name, fileUrl: undefined }
      const errorMessage = validateForm(newLens)
      setLens(newLens)
      setErrors({ ...errorMessage, lensFile: fileError || '' })
    }
    setLensFile(lensFiles)
    setFormDirty(true)
  }

  useEffect(() => {
    const asyncEvent = async () => {
      if (lensFile.length || !lens.blobName) return
      const blobUrl = await getMediaBlobUrl(LENS_CONTAINER, lens.blobName)
      setLensUrl(blobUrl || '')
    }
    asyncEvent()
  }, [lens.blobName, lensFile])

  const validateForm = (newLens: ILensCreate) => {
    const errorMessage = { ...errors }
    Object.keys(LensValidator).forEach((key) => (errorMessage[key] = LensValidator[key](newLens[key])))
    return errorMessage
  }

  const onFormInputChanged = (fieldName: string, value: string | number | boolean) => {
    const newLens = { ...lens, [fieldName]: value }
    setErrors({ ...validateForm(newLens) })
    setLens(newLens)
    setFormDirty(true)
  }

  const onSave = async () => {
    setSaving(true)
    const response = await saveLens({
      id: lens.id,
      title: lens.title,
      fileName: lens.fileName,
      opacity: 100,
      lensFile: lensFile[0],
      blobName: lens.blobName,
    })
    setSaving(false)

    if (!response || (response && response.success === false)) {
      addToast({
        type: 'error',
        message: 'Lens can not be created/updated. Please try again later',
        timeout: 5000,
      })
      return
    }

    const newLens = ResponseData(response) as ILens
    addToast({
      type: 'success',
      message: props.lens
        ? `Lens "${newLens.title}" has been successfully updated`
        : `A New Lens "${newLens.title}" has been created/updated successfully`,
      timeout: 5000,
    })

    setNewLensId(newLens.id || 0)
    refreshLensDetails()
    refreshLensList()
    if (onGoBack) {
      onGoBack(true)
      return
    }

    window.location.hash = '/'
  }

  const onCancel = () => {
    if (onGoBack) {
      onGoBack(false)
      return
    }
    window.location.hash = '/'
  }
  const disabledSave = !formDirty || (errors && (errors[LensFields.title] || errors[LensFields.fileName]))

  return (
    <div className={Style.lensContainer}>
      <Grid row cssClass={Style.heading}>
        <Grid cell item xs={12}>
          <Button label='Go back' icon='chevron_left' type='text' onClick={onCancel} />
          <h2>{!lens.id ? 'Create' : 'Edit'}</h2>
        </Grid>
      </Grid>

      <Grid row xs={12} gap={12} cssClass={Style.lensForm}>
        <Grid item xs={12}>
          <TextBox
            label='Lens Name'
            placeholder='Enter Lens Name here'
            error={errors[LensFields.title]}
            required={true}
            value={lens.title || ''}
            onChange={(value: string) => onFormInputChanged(LensFields.title, value)}
            limit={255}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputLabel label='Lens file' />
        </Grid>
        <Grid item xs={12} sm={12} cssClass={Style.uploadHolder}>
          <Uploader
            accept={['.svg', '.png', '.jpg', '.jpeg']}
            onFileChange={onLensFileChange}
            fileUrl={lensUrl}
            fileName={lens.fileName}
            maxSize={1}
            error={errors[LensFields.fileName]}
          />
        </Grid>

        <Grid item xs={12} sm={lens.createdAt ? 6 : 12}>
          <InputLabel label='Lens Creator' />
          <span>{lens.createdBy || user?.name}</span>
        </Grid>
        {lens.createdAt && (
          <Grid item xs={12} sm={6}>
            <InputLabel label='Created Date' />
            <span>{dateToString(lens.createdAt)}</span>
          </Grid>
        )}
      </Grid>
      <div className={Style.actionButtons}>
        <Button
          label='Cancel'
          type='secondary'
          disabled={saving}
          onClick={() => {
            !formDirty && onCancel()
            setOpenModal(true)
          }}
        />
        <Button label='Save' type='primary' disabled={disabledSave || saving} onClick={onSave} loading={saving} />
      </div>
      <ConfirmModal
        open={openModal}
        onYes={onCancel}
        onClose={() => setOpenModal(false)}
        title={`Cancel ${!props.lens ? 'Creating' : 'Editing'} Lens?`}
        message={`Canceling ${
          !props.lens ? 'Create' : 'Edit'
        } Lens can not be undone, all the data within the task will lost.`}
      />
    </div>
  )
}

export default LensForm
