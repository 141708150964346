import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Style from '../../styles/LookupBox.module.sass'
import TextBox from './TextBox'
import classNames from 'classnames'
import { ILookup } from '../../models/api/ILookup'

interface ILookupBox {
  label?: string
  required?: boolean
  error?: string
  disabled?: boolean
  placeholder?: string
  limit?: number
  selectedId?: number | null
  loading?: boolean
  displaySelectedItem?: boolean
  lookupItems: ILookup[]
  allowNewItem?: boolean
  modal?: boolean
  cssClass?: string
  minCharsBeforeOpen?: number
  validate?: (text: string) => string
  onBlur?: () => void
  onFocus?: () => void
  onClose?: () => void
  onSelectedItem: (item: ILookup) => void
}

const LookupBox: FC<ILookupBox> = (props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [searchText, setSearchText] = useState<string>()
  const [openResults, setOpenResults] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    label,
    required,
    disabled,
    placeholder,
    error,
    limit,
    selectedId,
    loading,
    lookupItems,
    displaySelectedItem,
    allowNewItem,
    cssClass,
    modal,
    minCharsBeforeOpen = 3,
    onBlur,
    onFocus,
    onClose,
    onSelectedItem,
    validate,
  } = props

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleWindowClick = (event: any) => {
      if (ref.current) {
        const currentState = ref.current
        if ((ref && currentState.contains(event.target)) || event.target.parentNode === null) return
        setOpenResults(false)
        onClose && onClose()
      }
    }

    window.addEventListener('click', handleWindowClick)
    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  }, [onClose])

  useEffect(() => {
    if (!selectedId) return
    const selectedItem = lookupItems.find((l) => l.id === selectedId)
    setSearchText(selectedItem?.title || '')
  }, [lookupItems, selectedId])

  useEffect(() => {
    setErrorMessage(error || '')
  }, [error])

  const onSelected = useCallback(
    (item: ILookup) => {
      onSelectedItem(item)
      setOpenResults(false)
      setSearchText(displaySelectedItem ? item.title : '')
      !displaySelectedItem && ref.current?.getElementsByTagName('input').item(0)?.focus()
    },
    [displaySelectedItem, onSelectedItem, setSearchText, setOpenResults],
  )

  const onSearchFocus = () => {
    displaySelectedItem && ref.current?.getElementsByTagName('input').item(0)?.select()
    setOpenResults(true)

    onFocus && onFocus()
  }
  const onChanged = (value: string) => {
    setOpenResults(value.length >= minCharsBeforeOpen)
    setSearchText(value)
    validate && setErrorMessage(validate(value))
  }

  const onKeyDown = (value: string) => {
    value === 'Tab' && setOpenResults(false)
  }

  const searchResults = useMemo(() => {
    if (!openResults) return null

    const noResultsMessage = () => {
      return allowNewItem && searchText && !errorMessage ? (
        <div className={Style.item} onClick={() => onSelected({ id: -1, title: searchText })}>
          Add "{searchText}"
        </div>
      ) : (
        <div className={Style.notFound}>No results found.</div>
      )
    }

    return (
      <div
        className={classNames({
          [Style.limit]: !!limit,
          [Style.list]: true,
          [Style.modal]: modal,
          [cssClass || '']: !!cssClass,
        })}
      >
        {lookupItems.filter((l) => l.title?.toLowerCase()?.includes(searchText?.toLowerCase() || '')).length === 0
          ? noResultsMessage()
          : lookupItems
              .filter((l) => l.title?.toLowerCase()?.includes(searchText?.toLowerCase() || ''))
              .map((res) => (
                <div key={res.id} className={Style.item} onClick={() => onSelected(res)}>
                  {res.title}
                </div>
              ))}
      </div>
    )
  }, [lookupItems, openResults, limit, searchText, allowNewItem, errorMessage, cssClass, modal, onSelected])

  return (
    <div ref={ref} className={Style.lookupBox}>
      <TextBox
        label={label}
        placeholder={placeholder}
        value={searchText || ''}
        onChange={onChanged}
        onBlur={onBlur}
        onFocus={onSearchFocus}
        required={required}
        disabled={disabled}
        limit={limit}
        loading={loading}
        error={errorMessage}
        onKeyDown={onKeyDown}
      ></TextBox>
      {searchResults}
    </div>
  )
}

export default LookupBox
