export enum TeamMemberRole {
  Contributor = 1,
  Editor = 2,
  Publisher = 3,
}

export const TeamMemberRoleText = {
  [TeamMemberRole.Contributor]: 'Contributor',
  [TeamMemberRole.Editor]: 'Editor',
  [TeamMemberRole.Publisher]: 'Publisher',
}
