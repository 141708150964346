import { FC, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import classNames from 'classnames'
import { Button, Dropdown, Grid, Pill, useToast } from '@aurecon-creative-technologies/styleguide'

import { addTeamMembers } from '../../api/TeamMemberService'
import { ITeamMemberModel } from '../../models/ITeamMemberModel'
import { TeamMemberRole, TeamMemberRoleText } from '../../enums/UserRolesEnum'
import UserBox from '../common/UserBox'
import { IUserModel } from '../../models/IUserModel'
import InputLabel from '../common/InputLabel'
import { TeamMembersData, useRefreshTeamMembers } from '../../stores/AppStore'
import { ResponseData } from '../../models/api/IResponse'

import Style from '../../styles/TeamMembers.module.sass'

const roles = [
  {
    id: TeamMemberRole.Contributor,
    label: TeamMemberRoleText[TeamMemberRole.Contributor],
  },
  {
    id: TeamMemberRole.Editor,
    label: TeamMemberRoleText[TeamMemberRole.Editor],
  },
  {
    id: TeamMemberRole.Publisher,
    label: TeamMemberRoleText[TeamMemberRole.Publisher],
  },
]

interface IAddTeamMembersProps {
  teamMembers: ITeamMemberModel[]
  deltamapId: number
}

const AddTeamMembers: FC<IAddTeamMembersProps> = (props) => {
  const { addToast } = useToast()
  const [memberList, setMemberList] = useState<IUserModel[]>([])
  const [memberRole, setMemberRole] = useState<number>()
  const [saving, setSaving] = useState(false)
  const membersData = useRecoilValueLoadable(TeamMembersData)
  const refreshTeamMembers = useRefreshTeamMembers()

  const { teamMembers, deltamapId } = props

  const removeMember = (id: string) => {
    const newList = [...memberList]
    const idx = newList.findIndex((it) => it.id === id)

    newList.splice(idx, 1)
    setMemberList(newList)
  }

  const addMembers = async () => {
    setSaving(true)

    const response = await addTeamMembers({
      deltamapId,
      roleId: memberRole || 0,
      users: memberList,
    })

    setSaving(false)

    if (!response || (response && response.success === false)) {
      addToast({
        type: 'error',
        message: `User(s) cannot be added. Please try again later`,
        timeout: 5000,
      })
      return
    }

    const newMembers = ResponseData(response)
    addToast({
      type: 'success',
      message: `${newMembers?.length || 0} User(s) have been added successfully.`,
      timeout: 5000,
    })
    setMemberList([])
    setMemberRole(undefined)
    refreshTeamMembers()
  }

  const addToMemberList = (item: IUserModel | null) => {
    item && setMemberList([...memberList, ...[item]])
  }

  return (
    <Grid row>
      <Grid item xs={12} sm={12} md={7} cssClass={Style.membersForm}>
        <InputLabel label='Member Email' />
        <div
          className={classNames({
            [Style.activeForm]: memberList.length > 0,
            [Style.addingForm]: true,
          })}
        >
          {!!memberList.length && (
            <div className={Style.memberList}>
              {memberList.map((member, index) => (
                <Pill
                  key={`${index}_${member.id}`}
                  cssClass={Style.pill}
                  colour={4}
                  onClose={() => removeMember(member.id)}
                >
                  {member.name}
                </Pill>
              ))}
            </div>
          )}

          <UserBox
            deltamapId={Number(deltamapId)}
            disabled={saving}
            selectedUserIds={[...memberList.map((mb) => mb.id), ...teamMembers.map((mb) => mb.auth0UserId)]}
            onSelectedItem={(item) => addToMemberList(item)}
            external
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <div className={Style.permission}>
          <InputLabel label='Permission' />
          <Dropdown items={roles} selectedItem={memberRole} onSelectItem={(role) => setMemberRole(role as number)} />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={2} cssClass={Style.addMemberButton}>
        <Button
          label='Add Team Member'
          flexible
          loading={saving}
          disabled={membersData.state !== 'hasValue' || !deltamapId || !memberRole || memberList.length === 0}
          onClick={addMembers}
        />
      </Grid>
    </Grid>
  )
}

export default AddTeamMembers
