import { TokenExpiryWrapper } from './TokenManager'
import { ITeamMemberModel } from '../models/ITeamMemberModel'
import {
  ITeamMemberBodyData,
  ITeamMemberPermissionModel,
  ITeamMemberRequestModel,
} from '../models/api/ITeamMemberRequestModel'
import { getAsync, patchAsync, postAsync } from '../helpers/apiRequest'
import { IResponse } from '../models/api/IResponse'
import { IDeltamapRequestModel } from '../models/api/IDeltamapRequestModel'

const apiUrl = '/team-members'

export const getTeamMembers = TokenExpiryWrapper(
  async (request: IDeltamapRequestModel): Promise<IResponse<ITeamMemberModel[]>> =>
    getAsync(`${apiUrl}/${request.deltamapId}`, request),
  [],
  null,
)

export const addTeamMembers = TokenExpiryWrapper(
  async (request: ITeamMemberRequestModel): Promise<IResponse<ITeamMemberModel[]>> =>
    postAsync<ITeamMemberRequestModel, ITeamMemberModel[]>(`${apiUrl}`, request),
  [],
  null,
)

export const activeOrDeactivedMembers = TokenExpiryWrapper(
  async (request: ITeamMemberBodyData): Promise<IResponse<ITeamMemberModel[]>> => patchAsync(`${apiUrl}`, request),
  [],
  null,
)

export const modifyMemberPermission = TokenExpiryWrapper(
  async (request: ITeamMemberPermissionModel): Promise<IResponse<boolean>> =>
    patchAsync(`${apiUrl}/permission`, request),
  [],
  null,
)
