import { FC, useCallback, useEffect, useState } from 'react'
import { Button, Icon, Loader } from '@aurecon-creative-technologies/styleguide'
import { dateToString } from '../../helpers/utils'
import { RevisionStatusEnum, RevisionStatusIcon, RevisionStatusText } from '../../enums/RevisionStatusEnum'

import { Circle, Group, Layer, Stage, Text } from 'react-konva'
import LensImage from '../visualisation/LensImage'
import { point } from '../hooks/useOffset'
import { ILens } from '../../models/ILensModel'
import { IRevisionTechnology } from '../../models/IRevisionTechnologyModel'
import { useDrawGridlines } from '../hooks/useDrawGridlines'

import Style from '../../styles/DeltamapCard.module.sass'

export interface IDeltamapCardProps {
  id: number
  title: string
  status: RevisionStatusEnum
  updatedDate: string
  market: string
  landscape: string
  client: string
  lens?: ILens
  revTech: IRevisionTechnology[]
  thumbnail?: string
}

const DeltamapCard: FC<IDeltamapCardProps> = (props) => {
  const { revTech, updatedDate } = props
  const actionActive = (status: RevisionStatusEnum) => status === props.status
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date(updatedDate))

  useEffect(() => {
    revTech.forEach((d) =>
      setLastUpdated((current) => {
        const updated = new Date(d.updatedAt || '')
        if (!current || updated > current) return updated
        return current
      }),
    )
  }, [revTech])

  const preferedWidth = 320
  const preferedHeight = 240
  const landing = 0 // the space on the left side where the list of technologies start
  const circleRadius = 5
  const padding = 5 // space outside the grid for legend, etc

  const offset = {
    landing,
    padding,
    width: preferedWidth / 2,
    height: preferedHeight / 2,
    stageWidth: preferedWidth / 2 + landing + 2 * padding,
    stageHeight: preferedHeight / 2 + 2 * padding,
    x: landing + padding,
    y: padding,
  }
  const gridlines = useDrawGridlines(offset, false)

  const handleOpenDeltamap = (tab: number) => {
    window.location.hash = `/deltamap/${props.id}/${tab}`
  }

  const createDataPoint = (data: IRevisionTechnology, point: point, idx: number, isPlaceholder?: boolean) => {
    const id = isPlaceholder ? `ph${data.technologyId.toString()}` : data.technologyId.toString()
    return (
      <Group key={`group${id}`} id={id} x={point.x} y={point.y}>
        <Circle
          key={`circle${id}`}
          id={id}
          x={0}
          y={circleRadius / 2}
          fill='#414BB2'
          radius={circleRadius}
          opacity={isPlaceholder ? 0.2 : 0.8}
        ></Circle>
        <Text
          text={`${idx + 1}`}
          fontSize={circleRadius}
          fontStyle={'bold'}
          align='center'
          verticalAlign='middle'
          key={`number${id}`}
          id={id}
          x={-circleRadius}
          y={-circleRadius / 2}
          height={circleRadius * 2}
          width={circleRadius * 2}
          fill='white'
          opacity={1}
        ></Text>
      </Group>
    )
  }

  const percentToPosition = (percent: point) => {
    return {
      x: Math.round(landing + padding + (percent.x * offset.width) / 100),
      y: Math.round(padding + (percent.y * offset.height) / 100),
    }
  }

  const drawDataPoints = useCallback(() => {
    const points: JSX.Element[] = []

    revTech?.forEach((d, idx) => {
      const point = percentToPosition(d)
      points.push(createDataPoint(d, point, idx))
    })
    return points
  }, [revTech]) // eslint-disable-line

  return (
    <div className={Style.cardContainer}>
      <div className={Style.cardContent}>
        <div className={Style.cardLandscape} title={props.landscape}>
          <span>{props.landscape}</span>
        </div>
        <div className={Style.cardThumbnail}>
          <div className={Style.stageHolder}>
            {props.lens ? (
              <Stage width={offset.stageWidth} height={offset.stageHeight} className={Style.stage}>
                <Layer>
                  <LensImage key={props.lens.id} lens={props.lens} opacity={props.lens.opacity || 50} offset={offset} />
                </Layer>
                <Layer>{gridlines}</Layer>

                <Layer>{drawDataPoints()}</Layer>
              </Stage>
            ) : (
              <Loader size='small' />
            )}
          </div>
        </div>
        <div className={Style.cardTitle} title={props.title}>
          <span>{props.title}</span>
        </div>
        <div className={Style.lineWrapper}>
          <span>
            <Icon type={RevisionStatusIcon[props.status]}></Icon>
            {RevisionStatusText[props.status]}
          </span>
          <span>{dateToString(lastUpdated)}</span>
        </div>
        <div className={Style.lineWrapper}>
          <span>{props.client}</span>
          <span>{props.market}</span>
        </div>
      </div>
      <div className={`${Style.actionButtons} deltamap-icon-button`}>
        {actionActive(RevisionStatusEnum.InProgress) && <Button label='Open' onClick={() => handleOpenDeltamap(4)} />}
        {!actionActive(RevisionStatusEnum.Published) && (
          <Button
            type='icon-square-secondary'
            icon='settings'
            onClick={() => handleOpenDeltamap(1)}
            cssClass={Style.settingsButton}
          />
        )}
      </div>
    </div>
  )
}

export default DeltamapCard
