import { getAsync } from '../helpers/apiRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { ILookup } from '../models/api/ILookup'
import { IResponse } from '../models/api/IResponse'
import { TokenExpiryWrapper } from './TokenManager'

const apiUrl = '/lookup'

export const getClients = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<ILookup[]>> => getAsync(`${apiUrl}/client`, request),
  [],
  null,
)

export const getLandscapes = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<ILookup[]>> => getAsync(`${apiUrl}/landscape`, request),
  [],
  null,
)

export const getMarkets = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<ILookup[]>> => getAsync(`${apiUrl}/market`, request),
  [],
  null,
)

export const getTechnologies = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<ILookup[]>> => getAsync(`${apiUrl}/technology`, request),
  [],
  null,
)
