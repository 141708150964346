import { FC, useEffect, useMemo, useState } from 'react'
import { Pagination, Container, Grid, Button, FormInput, Icon } from '@aurecon-creative-technologies/styleguide'

import LoadingScreen from '../components/LoadingScreen'
import Page from '../components/Page'

import { ILens } from '../models/ILensModel'

import LensCardView from '../components/lens/LensCardView'
import { LensLookup, NewLensId } from '../stores/AppStore'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import classNames from 'classnames'

import Style from '../styles/LensList.module.sass'

const LensList: FC = () => {
  const [searchText, setSearchText] = useState('')
  const [lenses, setLenses] = useState<ILens[] | null>(null)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [newLensId] = useRecoilState(NewLensId)
  const loadableLenses = useRecoilValueLoadable(LensLookup)

  useEffect(() => {
    if (loadableLenses.state !== 'hasValue' || !loadableLenses.contents) {
      setLoading(true)
      return
    }
    setLenses(loadableLenses.contents.map((lookup) => ({ ...lookup })))
    setLoading(false)
  }, [loadableLenses.contents, loadableLenses.state])

  const itemsPerPage = 8
  const skipItems = (page - 1) * itemsPerPage
  const cardsPerPage = page * itemsPerPage

  const noLenses = !lenses?.length

  const searchedLenses = useMemo(() => {
    if (!searchText || !lenses) return lenses || []

    return lenses.filter((lens) => {
      const matchesLensName = lens.title.toLowerCase().includes(searchText.toLowerCase())

      return matchesLensName
    })
  }, [lenses, searchText])

  if (loading)
    return (
      <Page footer>
        <div className={`${Style.lenses}`}>
          <Container cssClass={!lenses?.length ? Style.messageContainer : ''}>
            <LoadingScreen text='Loading lens list...' />
          </Container>
        </div>
      </Page>
    )
  const onHomeClicked = () => {
    window.location.hash = '#'
  }

  const homeClasses = classNames({
    [Style.home]: true,
  })

  return (
    <Page footer>
      <div className={`${Style.lenses}`}>
        <Container cssClass={!lenses?.length ? Style.messageContainer : ''}>
          <h2>Lenses</h2>
          <Grid row gap={12} cssClass={Style.toolBox}>
            <div className={Style.flexItem}>
              <Icon type='home' size='36px' className={homeClasses} onClick={onHomeClicked} />
            </div>
            <div className={Style.flexItem}>
              <Button type='primary' label='Create New' onClick={() => (window.location.hash = '/lens')} />
            </div>
            <div className={Style.flexItem}>
              <FormInput
                type='search'
                onChange={setSearchText}
                value={searchText}
                placeholder='Search by Name, ID...'
                disabled={noLenses}
              />
            </div>
          </Grid>

          <LensCardView
            newLensId={newLensId}
            lenses={searchedLenses || []}
            noLenses={noLenses}
            noData={lenses === null}
            skipItems={skipItems}
            cardsPerPage={cardsPerPage}
          />

          {!!lenses?.length && (
            <div className={Style.pagination}>
              <Pagination page={page} pageCount={Math.ceil(lenses.length / itemsPerPage) || 1} onChange={setPage} />
              <div className={Style.paginationFlexGrow}>
                {skipItems + 1} - {Math.min(cardsPerPage, lenses.length)} of {lenses.length} Lenses
              </div>
            </div>
          )}
        </Container>
      </div>
    </Page>
  )
}

export default LensList
