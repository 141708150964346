import { FC, useState } from 'react'
import {
  Button,
  FormInput,
  Loader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  IHeader,
  useToast,
  Dropdown,
  IDropdownItemProps,
} from '@aurecon-creative-technologies/styleguide'

import { TeamMemberRoleText } from '../../enums/UserRolesEnum'
import { activeOrDeactivedMembers, modifyMemberPermission } from '../../api/TeamMemberService'
import { ITeamMemberModel, ITeamMembersProps } from '../../models/ITeamMemberModel'
import { useRefreshTeamMembers } from '../../stores/AppStore'
import ConfirmModal from '../common/ConfirmModal'

import Style from '../../styles/TeamMembers.module.sass'

interface IPermissionChange {
  newRole: number | string
  member: ITeamMemberModel
}

const TeamMembersActive: FC<ITeamMembersProps> = (props) => {
  const { addToast } = useToast()
  const [page, setPage] = useState(1)
  const [deactivating, setDeactivating] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openPermissionModal, setOpenPermissionModal] = useState(false)
  const [permissionChange, setPermissionChange] = useState<IPermissionChange>()
  const [selectedMember, setSelectedMember] = useState<ITeamMemberModel>()
  const refreshTeamMembers = useRefreshTeamMembers()
  const {
    teamMembers,
    loading,
    searchText,
    setSearchText,
    nameSort,
    handleNameSort,
    permissionsSort,
    handlePermissionsSort,
  } = props

  const tableHeaders = [
    {
      label: 'Member Name',
      sort: nameSort,
      onSort: handleNameSort,
    },
    {
      label: 'Member Email',
    },
    {
      label: 'Permission',
      sort: permissionsSort,
      onSort: handlePermissionsSort,
    },
    {
      label: 'Action',
    },
  ] as IHeader[]

  const handleDeactiveMember = async () => {
    if (!selectedMember) {
      setOpenModal(false)
      return
    }

    setDeactivating(true)
    const response = await activeOrDeactivedMembers({
      userIds: [selectedMember.userId],
      deltamapId: selectedMember.deltamapId,
      active: false,
    })

    if (!response || (response && response.success === false))
      addToast({
        type: 'error',
        message: `User(s) cannot be deactivated. Please try again later.`,
        timeout: 5000,
      })
    else
      addToast({
        type: 'success',
        message: `${selectedMember.userName} has been deactivated successfully.`,
        timeout: 5000,
      })

    setDeactivating(false)
    setOpenModal(false)
    setSelectedMember(undefined)
    refreshTeamMembers()
  }

  const permissionList: IDropdownItemProps[] = []

  Object.keys(TeamMemberRoleText).forEach((role) => {
    permissionList.push({ id: role, label: TeamMemberRoleText[role] })
  })

  const handlePermissionChange = (newRole: string | number, member: ITeamMemberModel) => {
    if (!newRole || !member) return

    setPermissionChange({ newRole, member })
    setOpenPermissionModal(true)
  }

  const savePermissionChange = async () => {
    if (!permissionChange) return

    const result = await modifyMemberPermission({
      userId: permissionChange.member.userId,
      deltamapId: permissionChange.member.deltamapId,
      roleId: Number(permissionChange.newRole),
    })

    if (!result || (result && result.success === false))
      addToast({
        type: 'error',
        message: `User permission cannot be change. Please try again later.`,
        timeout: 5000,
      })
    else
      addToast({
        type: 'success',
        message: `Permission for ${permissionChange.member.userName} has been changed successfully.`,
        timeout: 5000,
      })

    setPermissionChange(undefined)
    refreshTeamMembers()
    setOpenPermissionModal(false)
  }

  const itemsPerPage = 10
  const skipItems = (page - 1) * itemsPerPage
  const itemPerPage = page * itemsPerPage

  if (loading) return <Loader label='Loading team members...' />

  return (
    <>
      <div className={Style.seachingContainer}>
        <FormInput
          type='search'
          onChange={setSearchText}
          value={searchText}
          cssClass={Style.searchBox}
          placeholder='Search by Member Name, Role,...'
        />
      </div>
      {!!teamMembers.length && (
        <>
          <Table headers={tableHeaders} cssClass={Style.teamMembersTable}>
            {teamMembers.slice(skipItems, itemPerPage).map((member) => (
              <TableRow key={`${member.deltamapId}_${member.userId}`}>
                <TableCell>{member.userName}</TableCell>
                <TableCell>{member.userEmail}</TableCell>
                <TableCell>
                  <Dropdown
                    items={permissionList}
                    selectedItem={`${member.roleId}`}
                    onSelectItem={(newRole) => handlePermissionChange(newRole, member)}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    type='icon-square'
                    icon='delete'
                    size='extra small'
                    onClick={() => {
                      setSelectedMember(member)
                      setOpenModal(true)
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <div className={Style.pagination}>
            <Pagination page={page} pageCount={Math.ceil(teamMembers.length / itemsPerPage) || 1} onChange={setPage} />
          </div>
        </>
      )}
      {!teamMembers.length && (
        <div className={Style.message}>
          <p>There are no active team members{searchText && ' matching your search'}.</p>
        </div>
      )}
      <ConfirmModal
        open={openModal}
        onYes={handleDeactiveMember}
        onClose={() => {
          setOpenModal(false)
          setSelectedMember(undefined)
        }}
        loading={deactivating}
        title={`Deactivate Users?`}
        message={`This action will revoke the User's access to this Deltamap. Are you sure you want to deactivate ${selectedMember?.userName}?`}
      />
      <ConfirmModal
        open={openPermissionModal}
        onYes={savePermissionChange}
        onClose={() => {
          setOpenPermissionModal(false)
          setPermissionChange(undefined)
        }}
        loading={deactivating}
        title={`Change user permission?`}
        message={
          permissionChange
            ? `Do you want to change ${permissionChange.member.userName}'s permission from ${
                TeamMemberRoleText[permissionChange.member.roleId]
              } to ${TeamMemberRoleText[permissionChange.newRole]} ?`
            : ''
        }
      />
    </>
  )
}

export default TeamMembersActive
