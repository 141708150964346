import { FC, useEffect, useState } from 'react'
import { FormInput, Loader } from '@aurecon-creative-technologies/styleguide'

import Style from '../../styles/TextBox.module.sass'

interface ITextBoxProps {
  value: string
  label?: string
  limit?: number
  required?: boolean
  error?: string
  disabled?: boolean
  placeholder?: string
  cssClass?: string
  loading?: boolean
  onBlur?: () => void
  onFocus?: () => void
  onChange: (value: string) => void
  onKeyDown?: (value: string) => void
}

const TextBox: FC<ITextBoxProps> = (props) => {
  const {
    value,
    label,
    error,
    cssClass,
    limit,
    required,
    loading,
    placeholder,
    disabled,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
  } = props

  const [charactersLeft, setCharactersLeft] = useState(limit)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setErrorMessage(error || '')
    limit && setCharactersLeft(limit - value.length)
  }, [error, limit, value])

  const onValueChange = (newValue: string) => {
    if (limit && limit < newValue.length) {
      onChange(newValue.substring(0, limit))
      return
    }

    if (newValue) setErrorMessage('')
    if (required && error && !newValue) setErrorMessage(error)

    onChange(newValue)
  }

  return (
    <div className={Style.textBoxWrapper}>
      {loading && <Loader cssClass={Style.loading} size='small' />}
      <FormInput
        type='text'
        required={required}
        error={errorMessage}
        cssClass={cssClass || Style.textInput}
        value={value}
        onChange={onValueChange}
        onBlur={onBlur}
        onFocus={onFocus}
        label={label}
        disabled={disabled}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
      />
      {props.limit && <div className={Style.characterLeft}>{charactersLeft} characters left.</div>}
    </div>
  )
}

export default TextBox
