import { FC, useEffect, useState } from 'react'

import Page from '../components/Page'
import DeltamapForm from '../components/deltamap/DeltamapForm'
import { appInsights } from '../api/AppInsights'

import { useParams } from 'react-router-dom'
import { Icon, ITab, TabContent, TabMenu } from '@aurecon-creative-technologies/styleguide'
import LoadingScreen from '../components/LoadingScreen'
import { IDeltamap } from '../models/IDeltamapModel'
import TeamMembers from '../components/members/TeamMembers'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { CurrentDeltamapId, CurrentRevisionId, DeltamapDetails } from '../stores/AppStore'
import Technologies from '../components/visualisation/Technologies'
import Visualise from '../components/visualisation/Visualise'
import classNames from 'classnames'

import Style from '../styles/Deltamap.module.sass'

const Deltamap: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Deltamap' })
  const { deltamapId, tab } = useParams<{ deltamapId: string; tab?: string }>()

  const setCurrentDeltamapId = useSetRecoilState(CurrentDeltamapId)
  const setCurrentRevisionId = useSetRecoilState(CurrentRevisionId)
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState(Number(tab || 1))
  const [deltamap, setDeltamap] = useState<IDeltamap | null>(null)
  const deltamapDetails = useRecoilValueLoadable(DeltamapDetails)
  const [dirty, setDirty] = useState<boolean>(false)

  const tabs: ITab[] = [
    { id: 1, label: 'Info', disabled: dirty },
    { id: 2, label: 'Team Members', disabled: !deltamapId || dirty },
    { id: 3, label: 'Technologies', disabled: !deltamapId || dirty },
    { id: 4, label: 'Visualise', disabled: !deltamapId || dirty },
  ]

  const onSelectTab = (tab: number) => {
    if (dirty) return
    if (tabs.find((t) => t.id === tab)?.disabled) return
    setActiveTab(tab)
    window.location.hash = `/deltamap/${deltamapId}/${tab}`
  }

  useEffect(() => {
    if (!deltamapId) {
      setCurrentDeltamapId(null)
      setCurrentRevisionId(null)
      setLoading(false)
      return
    }
    setCurrentDeltamapId(Number(deltamapId))
  }, [deltamapId, setCurrentDeltamapId, setCurrentRevisionId])

  useEffect(() => {
    if (deltamapDetails.state !== 'hasValue' || !deltamapDetails.contents) return

    setDeltamap(deltamapDetails.contents)
    setCurrentRevisionId(deltamapDetails.contents.revisionId)
    setLoading(false)
  }, [deltamapDetails.contents, deltamapDetails.state, setCurrentRevisionId])

  const onDirty = (isDirty: boolean) => {
    setDirty(isDirty)
  }
  const onHomeClicked = () => {
    if (dirty) return
    window.location.hash = '#'
  }

  const homeClasses = classNames({
    [Style.home]: true,
    [Style.disabled]: dirty,
  })
  return (
    <Page footer>
      <div className={Style.formContainer}>
        <Icon type='home' size='36px' className={homeClasses} onClick={onHomeClicked} />
        <TabMenu
          tabs={tabs || []}
          size='medium'
          defaultActiveTab={activeTab}
          cssClass={Style.tabMenu}
          onSelectTab={onSelectTab}
          type='line'
        >
          <TabContent for={1}>{loading ? <LoadingScreen /> : <DeltamapForm deltamap={deltamap} />}</TabContent>
          <TabContent for={2}>{loading ? <LoadingScreen /> : <TeamMembers />}</TabContent>
          <TabContent for={3}>
            {loading || !deltamap ? (
              <LoadingScreen />
            ) : (
              <Technologies
                revisionTechnologies={deltamap.revisionTechnologies}
                revisionId={deltamap?.revisionId}
                onDirty={onDirty}
              />
            )}
          </TabContent>
          <TabContent for={4}>
            {loading || !deltamap ? <LoadingScreen /> : <Visualise deltaMap={deltamap} onDirty={onDirty} />}
          </TabContent>
        </TabMenu>
      </div>
    </Page>
  )
}

export default Deltamap
