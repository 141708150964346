import { getMediaBlobUrl, ISaveMediaResponseModel, saveMedia } from '../api/StorageService'
import { LENS_CONTAINER, DEFAULT_LENS_EXTENSIONS } from '../config/config'
import { ResponseData } from '../models/api/IResponse'

export enum DocumentType {
  Unknown = 0,
  Image = 1,
  Video = 2,
  Document = 3,
}
export const fileExtensionTypes = {
  '.svg': DocumentType.Image,
  '.jpg': DocumentType.Image,
  '.jpeg': DocumentType.Image,
  '.png': DocumentType.Image,
  '.gif': DocumentType.Image,
  '.webp': DocumentType.Image,
  '.pjp': DocumentType.Image,
  '.pjpeg': DocumentType.Image,
  '.jfif': DocumentType.Image,
  '.mp4': DocumentType.Video,
  '.m4v': DocumentType.Video,
  '.webm': DocumentType.Video,
  '.ogm': DocumentType.Video,
  '.ogv': DocumentType.Video,
  '.pdf': DocumentType.Document,
  '.docx': DocumentType.Document,
  '.doc': DocumentType.Document,
  '.rtf': DocumentType.Document,
  '.txt': DocumentType.Document,
  '.xlsx': DocumentType.Document,
  '.xls': DocumentType.Document,
  '.csv': DocumentType.Document,
}
const getContainerByExtension = (ext: string) => {
  if (DEFAULT_LENS_EXTENSIONS.some((s) => ext.toLowerCase().includes(s))) return LENS_CONTAINER
  return null
}

export const getExtensionFromFilename = (fileName?: string): string => {
  if (!fileName?.length || fileName.lastIndexOf('.') < 1) return '.unknown'
  return fileName.substring(fileName.lastIndexOf('.')).toLowerCase()
}

export const uploadMediaReturnSasUrl = async (
  reportId: number | null,
  contentFile: File,
): Promise<{ default: string }> => {
  if (!reportId) return { default: '' }

  const response = await uploadMedia(reportId, contentFile)

  if (!response) return { default: '' }

  const blobUrl = await getMediaBlobUrl(response.container, response.mediaId)
  return { default: blobUrl ?? '' }
}

export const uploadMedia = async (
  reportId: number | null,
  contentFile: File,
): Promise<ISaveMediaResponseModel | null> => {
  if (!reportId) return null

  const ext = getExtensionFromFilename(contentFile.name)
  const container = getContainerByExtension(ext)
  if (!container) return null

  return ResponseData(
    await saveMedia({
      reportId,
      container,
      contentFile,
    }),
  )
}
