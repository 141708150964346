import { offset } from './useOffset'
import { Arrow, Rect, Text } from 'react-konva'

export const useDrawGridlines = (offset: offset, arrows = true): JSX.Element => {
  const gridlines = (
    <>
      <Rect
        width={offset.width}
        x={offset.x}
        y={offset.y + offset.height * 0.333}
        height={1}
        fill='green'
        strokeEnabled={false}
      />

      <Rect
        width={offset.width}
        x={offset.x}
        y={offset.y + offset.height * 0.666}
        height={1}
        fill='green'
        strokeEnabled={false}
      />

      <Rect
        height={offset.height}
        x={offset.x + offset.width * 0.25}
        y={offset.y}
        width={1}
        fill='green'
        strokeEnabled={false}
      />

      <Rect
        height={offset.height}
        x={offset.x + offset.width * 0.5}
        y={offset.y}
        width={1}
        fill='green'
        strokeEnabled={false}
      />

      <Rect
        height={offset.height}
        x={offset.x + offset.width * 0.75}
        y={offset.y}
        width={1}
        fill='green'
        strokeEnabled={false}
      />

      <Rect
        width={offset.width}
        height={offset.height}
        x={offset.x}
        y={offset.y}
        stroke='green'
        strokeWidth={1}
        fillEnabled={false}
      ></Rect>
      {arrows && (
        <>
          <Arrow
            key={'EvolutionArrow'}
            points={[
              offset.x,
              offset.y + offset.height + offset.padding / 4,
              offset.x + offset.width,
              offset.y + offset.height + offset.padding / 4,
            ]}
            stroke={'black'}
            strokeWidth={1}
          />

          <Text
            text={'Evolution'}
            fontSize={14}
            align='center'
            verticalAlign='middle'
            key={`Evolution`}
            x={offset.x}
            y={offset.y + offset.height}
            width={offset.width}
            height={offset.padding}
            fill='black'
          ></Text>

          <Arrow
            key={'ApplicationArrow'}
            points={[offset.x - offset.padding / 4, offset.y, offset.x - offset.padding / 4, offset.y + offset.height]}
            stroke={'black'}
            strokeWidth={1}
          />

          <Text
            text={'Application'}
            fontSize={14}
            align='center'
            verticalAlign='middle'
            rotation={270}
            key={`Application`}
            x={offset.x - offset.padding}
            y={offset.y + offset.height}
            width={offset.height}
            height={offset.padding}
            fill='black'
          ></Text>
        </>
      )}
    </>
  )

  return gridlines
}
