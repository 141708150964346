import { FC } from 'react'
import { ILens } from '../../models/ILensModel'
import useImage from 'use-image'
import { Image } from 'react-konva'
import { offset } from '../hooks/useOffset'

interface ILensImageProps {
  lens?: ILens
  opacity: number
  offset: offset
}

const LensImage: FC<ILensImageProps> = (props) => {
  const { lens, opacity, offset } = props
  const [img, status] = useImage(lens?.blobUrl || '')

  if (!lens || !lens.blobUrl || status !== 'loaded') return null

  return (
    <Image
      image={img}
      opacity={opacity / 100}
      width={offset.width}
      x={offset.x}
      y={offset.y}
      height={offset.height}
    ></Image>
  )
}

export default LensImage
