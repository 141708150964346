import { FC, useEffect, useMemo, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { Grid, ITab, TabContent, TabMenu } from '@aurecon-creative-technologies/styleguide'
import { compareAsc, compareDesc, parseISO } from 'date-fns'

import { ITeamMemberModel } from '../../models/ITeamMemberModel'
import LoadingScreen from '../LoadingScreen'
import { TeamMembersData, DeltamapDetails } from '../../stores/AppStore'
import AddTeamMembers from './AddTeamMembers'
import TeamMembersActive from './TeamMembersActive'
import TeamMembersDeactived from './TeamMembersDeactived'
import { TeamMemberRoleText } from '../../enums/UserRolesEnum'

import Style from '../../styles/TeamMembers.module.sass'

const tabOptions: ITab[] = [
  {
    id: 1,
    label: 'Active Members',
  },
  {
    id: 2,
    label: 'Deactived Members',
  },
]

const TeamMembers: FC = () => {
  const [teamMembers, setTeamMembers] = useState<ITeamMemberModel[]>([])
  const [deltamapId, setDeltamapId] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [nameSort, setNameSort] = useState('asc')
  const [permissionsSort, setPermissionsSort] = useState('none')
  const [deactivetedDateSort, setDeactivetedDateSort] = useState('none')
  const deltamapDetails = useRecoilValueLoadable(DeltamapDetails)
  const membersData = useRecoilValueLoadable(TeamMembersData)

  useEffect(() => {
    if (deltamapDetails.state !== 'hasValue') return

    setDeltamapId(deltamapDetails.contents?.id || 0)
  }, [deltamapDetails.contents, deltamapDetails.state])

  useEffect(() => {
    if (membersData.state !== 'hasValue') return

    setTeamMembers(membersData.contents || [])
    return () => setTeamMembers([])
  }, [membersData.state, membersData.contents])

  const teamMembersFiltered = useMemo(() => {
    const members = !searchText
      ? [...teamMembers]
      : [...teamMembers].filter((member) => {
          const searchTextLowercase = searchText.toLowerCase()

          return (
            member.userName.toLowerCase().includes(searchTextLowercase) ||
            member.userEmail.toLowerCase().includes(searchTextLowercase)
          )
        })

    if (nameSort !== 'none')
      return members.sort((a, b) => {
        const sort = nameSort === 'asc' ? 1 : -1
        return a.userName > b.userName ? sort : -sort
      })

    if (permissionsSort !== 'none')
      return members.sort((a, b) => {
        const sort = permissionsSort === 'asc' ? 1 : -1
        return TeamMemberRoleText[a.roleId] > TeamMemberRoleText[b.roleId] ? sort : -sort
      })

    if (deactivetedDateSort !== 'none')
      return members.sort((a, b) => {
        if (!a.deactiveDate || !b.deactiveDate) return -1
        const dateA = parseISO(a.deactiveDate)
        const dateB = parseISO(b.deactiveDate)

        if (deactivetedDateSort === 'desc') return compareDesc(dateA, dateB)
        else return compareAsc(dateA, dateB)
      })

    return members
  }, [searchText, teamMembers, nameSort, permissionsSort, deactivetedDateSort])

  const handleNameSort = (newNameSort: string) => {
    setPermissionsSort('none')
    setDeactivetedDateSort('none')
    setNameSort(newNameSort)
  }

  const handlePermissionsSort = (newPermissionsSort: string) => {
    setNameSort('none')
    setDeactivetedDateSort('none')
    setPermissionsSort(newPermissionsSort)
  }

  const handleDeactivetedDateSort = (newDeactivatedDateSort: string) => {
    setNameSort('none')
    setPermissionsSort('none')
    setDeactivetedDateSort(newDeactivatedDateSort)
  }

  if (deltamapDetails.state !== 'hasValue') return <LoadingScreen text='Loading team members...' />

  return (
    <div className={Style.teamMemberContainer}>
      <Grid row>
        <Grid cell item xs={12}>
          <h2>Team Members</h2>
        </Grid>
      </Grid>

      <AddTeamMembers teamMembers={teamMembers} deltamapId={Number(deltamapId)} />

      <Grid row cssClass={Style.memberList}>
        <Grid item xs={12}>
          <p className={Style.memberListTitle}>Member List</p>
        </Grid>
        <Grid item xs={12} cssClass={Style.tabWrapper}>
          <TabMenu tabs={tabOptions} defaultActiveTab={tabOptions[0].id} cssClass={Style.memberTabs}>
            <TabContent for={1}>
              <TeamMembersActive
                teamMembers={teamMembersFiltered.filter((tm) => tm.active)}
                loading={membersData.state !== 'hasValue'}
                searchText={searchText}
                setSearchText={setSearchText}
                nameSort={nameSort}
                handleNameSort={handleNameSort}
                permissionsSort={permissionsSort}
                handlePermissionsSort={handlePermissionsSort}
              />
            </TabContent>
            <TabContent for={2}>
              <TeamMembersDeactived
                teamMembers={teamMembersFiltered.filter((tm) => !tm.active)}
                loading={membersData.state !== 'hasValue'}
                searchText={searchText}
                setSearchText={setSearchText}
                nameSort={nameSort}
                handleNameSort={handleNameSort}
                permissionsSort={permissionsSort}
                handlePermissionsSort={handlePermissionsSort}
                deactivetedDateSort={deactivetedDateSort}
                handleDeactivetedDateSort={handleDeactivetedDateSort}
              />
            </TabContent>
          </TabMenu>
        </Grid>
      </Grid>
    </div>
  )
}

export default TeamMembers
