import { FC, useEffect, useState } from 'react'

import { IUserModel } from '../../models/IUserModel'
import { searchUsers } from '../../api/UserService'
import SuggestionBox from './SuggestionBox'
import { ISuggestionItem } from '../../models/ISuggestionItem'
import { ResponseData } from '../../models/api/IResponse'

interface IUserBoxProps {
  deltamapId: number
  label?: string
  required?: boolean
  error?: string
  disabled?: boolean
  placeholder?: string
  selectedUserIds?: string[]
  selectedItem?: IUserModel | null
  allowNewItem?: boolean
  cssClass?: string
  modal?: boolean
  external?: boolean
  validate?: (text: string) => string
  onSelectedItem: (user: IUserModel | null) => void
}

const UserBox: FC<IUserBoxProps> = (props) => {
  const [users, setUsers] = useState<IUserModel[]>([])
  const [searching, setSearching] = useState(false)
  const [openResults, setOpenResults] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    label,
    required,
    disabled,
    placeholder,
    error,
    selectedItem,
    selectedUserIds,
    allowNewItem,
    cssClass,
    modal,
    external,
    validate,
    onSelectedItem,
  } = props

  useEffect(() => {
    if (selectedItem) {
      setErrorMessage('')
      return
    }

    setErrorMessage(error || '')
  }, [error, selectedItem])

  const onSearch = async (value: string) => {
    if (!value || value.length < 3) {
      setSearching(false)
      setOpenResults(false)
      setUsers([])
      onSelectedItem(null)
      setErrorMessage(error || '')
      return
    }

    setErrorMessage('')
    setSearching(true)
    setOpenResults(true)

    const userList = ResponseData(await searchUsers({ deltamapId: props.deltamapId, email: value, external })) || []

    const filteredUsers = userList.filter(
      (user) => !(selectedUserIds?.includes(user.auth0UserId) || selectedUserIds?.includes(user.email || '')),
    )
    const uniqueUsers = [...new Map(filteredUsers.map((item) => [item['auth0UserId'], item])).values()]

    setUsers(uniqueUsers)
    setSearching(false)
  }

  const onSelectedUser = (item: ISuggestionItem) => {
    onSelectedItem({ id: item.id, email: item.value, auth0UserId: item.id, name: item.label || item.value })
    setUsers([])
  }

  return (
    <SuggestionBox
      label={label}
      placeholder={placeholder}
      required={required}
      error={errorMessage}
      disabled={disabled}
      open={openResults}
      searching={searching}
      selectedItem={selectedItem?.name}
      suggestionItems={users.map((user) => ({
        id: user.auth0UserId || '',
        value: user.email || '',
        displayValue: user.name,
      }))}
      onSearch={onSearch}
      onSelectedItem={onSelectedUser}
      allowNewItem={allowNewItem}
      cssClass={cssClass}
      validate={validate}
      modal={modal}
    />
  )
}

export default UserBox
