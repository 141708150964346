export const SUPPORT_EMAIL = 'deltamap.support@aurecongroup.com'
export const JIRA_SUPPORT_URL = 'https://aurecondigital.atlassian.net/servicedesk/customer/portal/24'
export const ABOUT_URL = 'https://www.aurecongroup.com/expertise/environmental-management'
export const REQUEST_ACCESS_URL = 'https://aurecondigital.atlassian.net/servicedesk/customer/portal/24'
export const MAX_FILE_NAME = 80

const config = {
  AUTH0_DOMAIN: 'creativetechnologies.au.auth0.com',
  AUTH0_CLIENT_ID: 'h6Ce5uEcFVzg8B5RaG2XnXgroc4Je4Ey',
  AUTH0_AUDIENCE: 'https://deltamaps.aurecongroup.digital',
  AUTH0_LOGOUT_RETURN: window.location.origin,
  AUTH0_ROLES: 'https://aurecongroup.com/roles',
  AUTH0_GLOBAL_ADMIN_ROLE: 'Deltamap Global Admin',
}

export const DEFAULT_LENS_EXTENSIONS = ['jpeg', 'jpg', 'png', 'gif', 'webp', 'jfif', 'pjpeg', 'pjp']
export const LENS_CONTAINER = 'lens'

export default config
