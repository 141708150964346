import { useCallback } from 'react'
import { selector, atom, useSetRecoilState } from 'recoil'
import { getAppVersion } from '../api/AppService'
import { getDeltamapDetails } from '../api/DeltamapService'
import { getLensDetails, getLenses } from '../api/LensService'
import { getClients, getLandscapes, getMarkets, getTechnologies } from '../api/LookupService'
import { getRevisionMaps } from '../api/MapService'
import { getMediaBlobUrl } from '../api/StorageService'
import { getTeamMembers } from '../api/TeamMemberService'
import { LENS_CONTAINER } from '../config/config'
import { ILookup } from '../models/api/ILookup'
import { ResponseData } from '../models/api/IResponse'
import { IDeltamap } from '../models/IDeltamapModel'
import { ILens } from '../models/ILensModel'
import { IMap } from '../models/IMapModel'

export const AppVersion = selector({
  key: 'version',
  get: async () => {
    const response = await getAppVersion()
    return response.data?.version
  },
})

export const TermsModal = atom({
  key: 'terms_modal',
  default: false,
})

export const NewDeltamapId = atom<number | null>({
  key: 'new_deltamap_id',
  default: null,
})

export const CurrentDeltamapId = atom<number | null>({
  key: 'current_deltamap_id',
  default: null,
})

export const CurrentRevisionId = atom<number | null>({
  key: 'current_deltamap_revision_id',
  default: null,
})

export const DeltamapDetails = selector({
  key: 'deltamap_details',
  get: async ({ get }): Promise<IDeltamap | null> => {
    const deltamapId = get(CurrentDeltamapId)
    if (deltamapId) return ResponseData(await getDeltamapDetails({ deltamapId }))
    return null
  },
})

export const RevisionMaps = selector({
  key: 'revision_maps',
  get: async ({ get }): Promise<IMap[] | null> => {
    const revisionId = get(CurrentRevisionId)
    if (revisionId) return ResponseData(await getRevisionMaps({ revisionId }))
    return null
  },
})

export const TeamMembersData = selector({
  key: 'team_members',
  get: async ({ get }) => {
    get(RefreshTeamMember)
    const deltamapId = get(CurrentDeltamapId)
    return deltamapId ? ResponseData(await getTeamMembers({ deltamapId })) : []
  },
})

export const RefreshTeamMember = atom({
  key: 'refresh_team_member',
  default: 0,
})

export const useRefreshTeamMembers = (): React.EffectCallback => {
  const setRefreshTeamMembers = useSetRecoilState(RefreshTeamMember)
  return useCallback(() => setRefreshTeamMembers((v) => v + 1), [setRefreshTeamMembers])
}

export const ClientLookup = selector({
  key: 'client_lookup',
  get: async (): Promise<ILookup[] | null> => {
    return ResponseData(await getClients({}))
  },
})

export const LandscapeLookup = selector({
  key: 'landscape_lookup',
  get: async (): Promise<ILookup[] | null> => {
    return ResponseData(await getLandscapes({}))
  },
})

export const MarketLookup = selector({
  key: 'market_lookup',
  get: async (): Promise<ILookup[] | null> => {
    return ResponseData(await getMarkets({}))
  },
})

export const TechnologyLookup = selector({
  key: 'technology_lookup',
  get: async (): Promise<ILookup[] | null> => {
    return ResponseData(await getTechnologies({}))
  },
})

export const LensLookup = selector({
  key: 'lens_lookup',
  get: async (): Promise<ILens[] | null> => {
    const lensList = ResponseData(await getLenses({}))
    if (lensList?.length) {
      for (let index = 0; index < lensList.length; index++) {
        const lens = lensList[index]
        if (lens.blobName && !lens.blobUrl) lens.blobUrl = (await getMediaBlobUrl(LENS_CONTAINER, lens.blobName)) || ''
      }
    }
    return lensList || []
  },
})

export const NewLensId = atom<number | null>({
  key: 'new_lens_id',
  default: null,
})

export const CurrentLensId = atom<number | null>({
  key: 'current_lens_id',
  default: null,
})

export const LensDetails = selector({
  key: 'lens_details',
  get: async ({ get }): Promise<ILens | null> => {
    const lensId = get(CurrentLensId)
    if (lensId) return ResponseData(await getLensDetails({ lensId }))
    return null
  },
})
