import { TokenExpiryWrapper } from './TokenManager'
import { IDeltamapFormData, IDeltamapRequestModel } from '../models/api/IDeltamapRequestModel'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { getAsync, postMultiFormDataAsync } from '../helpers/apiRequest'
import { IResponse } from '../models/api/IResponse'
import { HttpMethod } from '../enums/ApiRequestConstants'
import { IDeltamap } from '../models/IDeltamapModel'

const apiUrl = '/deltamaps'

export const getDeltamaps = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<IDeltamap[]>> => getAsync(`${apiUrl}`, request),
  [],
  null,
)

export const getDeltamapDetails = TokenExpiryWrapper(
  (request: IDeltamapRequestModel): Promise<IResponse<IDeltamap>> =>
    getAsync(`${apiUrl}/${request.deltamapId}`, request),
  [],
  null,
)

export const saveDeltamap = TokenExpiryWrapper(
  async (request: IDeltamapFormData): Promise<IResponse<IDeltamap>> => {
    try {
      const formData = new FormData()
      Object.keys(request)
        .filter((key) => key !== 'token')
        .forEach((key) => {
          formData.set(key, request[key] || '')
        })
      return await postMultiFormDataAsync(
        `${apiUrl}`,
        request.token || '',
        formData,
        request.id ? HttpMethod.PUT : HttpMethod.POST,
      )
    } catch (error) {
      const response = error as Response
      return {
        success: false,
        message: await response.text(),
      }
    }
  },
  [],
  null,
)
