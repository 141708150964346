import { FC, useEffect, useState } from 'react'
import classnames from 'classnames'

import { IDeltamap } from '../../models/IDeltamapModel'
import DeltamapCard from './DeltamapCard'
import NoDeltamapsMessage from './NoDeltamapsMessage'

import Style from './../../styles/DeltamapCardView.module.sass'
import { Grid } from '@aurecon-creative-technologies/styleguide'
import { useRecoilValueLoadable } from 'recoil'
import { LensLookup } from '../../stores/AppStore'
import { ILens } from '../../models/ILensModel'

interface IDeltamapCardViewProps {
  newDeltamapId: number | null
  deltamaps: IDeltamap[]
  noDeltamaps: boolean
  noData: boolean
  skipItems: number
  cardsPerPage: number
}

const DeltamapCardView: FC<IDeltamapCardViewProps> = (props) => {
  const { newDeltamapId, deltamaps, noDeltamaps, noData, skipItems, cardsPerPage } = props
  const [lenses, setLenses] = useState<ILens[]>()
  const loadableLenses = useRecoilValueLoadable(LensLookup)

  useEffect(() => {
    if (loadableLenses.state !== 'hasValue' || !loadableLenses.contents) return
    setLenses(loadableLenses.contents.map((lookup) => ({ ...lookup })))
  }, [loadableLenses.contents, loadableLenses.state])

  if (!deltamaps.length) return <NoDeltamapsMessage noData={noData} noDeltamaps={noDeltamaps} />

  return (
    <Grid row gap={12}>
      {deltamaps.slice(skipItems, cardsPerPage).map((deltamap) => (
        <Grid key={deltamap.id} item xs={12} sm={6} lg={3} xl={3}>
          <Grid
            cell
            item
            cssClass={classnames({
              [Style.hightLight]: deltamap.id === newDeltamapId,
            })}
          >
            <DeltamapCard
              id={deltamap.id}
              title={deltamap.title}
              landscape={deltamap.landscape}
              client={deltamap.client}
              market={deltamap.market}
              status={deltamap.revisionStatus}
              updatedDate={deltamap.revisionUpdated || deltamap.createdAt || ''}
              lens={lenses?.find((l) => l.id === deltamap.lensId)}
              revTech={deltamap.revisionTechnologies}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default DeltamapCardView
