import { RevisionStatusEnum } from './RevisionStatusEnum'

export enum FilterOptionsEnum {
  AllStatuses = 0,
  InProgress = RevisionStatusEnum.InProgress,
  Published = RevisionStatusEnum.Published,
  Closed = RevisionStatusEnum.Closed,
}

export const FilterLabels = {
  [FilterOptionsEnum.AllStatuses]: 'All Statuses',
  [FilterOptionsEnum.InProgress]: 'In Progress',
  [FilterOptionsEnum.Published]: 'Published',
  [FilterOptionsEnum.Closed]: 'Closed',
}

export enum SortOptionsEnum {
  DeltamapCreatedDate = 1,
  DeltamapModifiedDate = 2,
  DeltamapName = 3,
  DeltamapStatus = 5,
}

export const SortLabels = {
  [SortOptionsEnum.DeltamapCreatedDate]: 'Created Date',
  [SortOptionsEnum.DeltamapModifiedDate]: 'Modified Date',
  [SortOptionsEnum.DeltamapName]: 'Name',
  [SortOptionsEnum.DeltamapStatus]: 'Status',
}

export enum DeltamapListSortOrder {
  asc = -1,
  desc = 1,
}

export const filterOptions = [
  {
    id: FilterOptionsEnum.AllStatuses.toString(),
    label: FilterLabels[FilterOptionsEnum.AllStatuses],
  },
  {
    id: FilterOptionsEnum.InProgress.toString(),
    label: FilterLabels[FilterOptionsEnum.InProgress],
  },
  {
    id: FilterOptionsEnum.Published.toString(),
    label: FilterLabels[FilterOptionsEnum.Published],
  },
  {
    id: FilterOptionsEnum.Closed.toString(),
    label: FilterLabels[FilterOptionsEnum.Closed],
  },
]

export const sortOptions = [
  {
    id: SortOptionsEnum.DeltamapModifiedDate.toString(),
    label: SortLabels[SortOptionsEnum.DeltamapModifiedDate],
  },
  {
    id: SortOptionsEnum.DeltamapCreatedDate.toString(),
    label: SortLabels[SortOptionsEnum.DeltamapCreatedDate],
  },
  {
    id: SortOptionsEnum.DeltamapName.toString(),
    label: SortLabels[SortOptionsEnum.DeltamapName],
  },
  {
    id: SortOptionsEnum.DeltamapStatus.toString(),
    label: SortLabels[SortOptionsEnum.DeltamapStatus],
  },
]
