import { Grid } from '@aurecon-creative-technologies/styleguide'
import { FC } from 'react'

import Style from '../../styles/NoDeltamapsMessage.module.sass'

interface INoDeltamapsMessageProps {
  noData: boolean
  noDeltamaps: boolean
}

const NoDeltamapsMessage: FC<INoDeltamapsMessageProps> = (props) => {
  const { noDeltamaps, noData } = props

  return (
    <Grid row gap={12} cssClass={Style.messageWrapper}>
      {noData && (
        <div className={Style.message}>
          There are currently no deltamaps assigned to you.
          <br />
          Please contact your deltamap manager.
        </div>
      )}

      {noDeltamaps && !noData && <div className={Style.message}>No deltamaps have been created yet.</div>}

      {!noDeltamaps && !noData && (
        <div className={Style.message}>
          No deltamaps found.
          <br />
          Try adjusting your search or filter to find what you're looking for.
        </div>
      )}
    </Grid>
  )
}

export default NoDeltamapsMessage
