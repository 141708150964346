import { TokenExpiryWrapper } from './TokenManager'
import { IUserModel } from '../models/IUserModel'
import { IUserRequestModel } from '../models/api/IUserRequestModel'
import { getAsync } from '../helpers/apiRequest'
import { IResponse } from '../models/api/IResponse'

export const searchUsers = TokenExpiryWrapper(
  (request: IUserRequestModel): Promise<IResponse<IUserModel[]>> => {
    return getAsync(`/users/search/${request.email}`, request)
  },
  [],
  null,
)
