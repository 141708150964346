import { IButtonProps, Grid, Modal } from '@aurecon-creative-technologies/styleguide'
import { FC, ReactNode } from 'react'

import Style from '../../styles/ConfirmModal.module.sass'

interface ConfirmModalProps {
  title: string
  message: string | ReactNode
  open: boolean
  loading?: boolean
  onYes?: () => void
  onNo?: () => void
  onClose: () => void
  labelYes?: string
  labelClose?: string
  hideYes?: boolean
  cssClass?: string
  showCloseButton?: boolean
  size?: 'small' | 'medium' | 'large'
}

const ConfirmModal: FC<ConfirmModalProps> = (props) => {
  const actions: IButtonProps[] = []

  if (!props.hideYes) {
    actions.push({
      type: 'secondary',
      label: props.labelYes || 'Yes',
      cssClass: Style.actionButton,
      loading: props.loading,
      disabled: props.loading,
      onClick: () => props.onYes && props.onYes(),
    })
  }

  actions.push({
    type: 'primary',
    label: props.labelClose || 'No',
    disabled: props.loading,
    cssClass: Style.actionButton,
    onClick: () => (props.onNo ? props.onNo() : props.onClose()),
  })

  return (
    <Modal
      isShowing={props.open}
      shouldOverlayClose={false}
      isOverlay
      isCloseButton={props.showCloseButton}
      modalPadding={{ top: '0', right: '24px', left: '24px', bottom: '24px' }}
      onClose={props.onClose}
      actions={actions}
      cssClass={props.cssClass}
      size={props.size || 'medium'}
    >
      <Grid row gap={10} cssClass={Style.confirmModalContainer}>
        <Grid item xs={12}>
          <h2>{props.title}</h2>
          {props.message}
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ConfirmModal
