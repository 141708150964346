import { FC, useEffect, useState } from 'react'

import Page from '../components/Page'
import LensForm from '../components/lens/LensForm'
import { appInsights } from '../api/AppInsights'

import { useParams } from 'react-router-dom'
import { Container, Icon, Loader } from '@aurecon-creative-technologies/styleguide'
import { ILens } from '../models/ILensModel'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import classNames from 'classnames'
import { CurrentLensId, LensDetails } from '../stores/AppStore'

import Style from '../styles/Lens.module.sass'

const Lens: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Lens' })
  const { lensId } = useParams<{ lensId: string }>()

  const setCurrentLensId = useSetRecoilState(CurrentLensId)
  const [lens, setLens] = useState<ILens | null>(null)
  const lensDetails = useRecoilValueLoadable(LensDetails)

  useEffect(() => {
    if (!lensId) {
      setCurrentLensId(null)
      setLens(null)
      return
    }
    setCurrentLensId(Number(lensId))
  }, [lensId, setCurrentLensId])

  useEffect(() => {
    if (lensDetails.state !== 'hasValue' || !lensDetails.contents) return
    if (lensId && lensDetails.contents.id === Number(lensId)) setLens(lensDetails.contents)
  }, [lensDetails.contents, lensDetails.state, lensId])

  const onHomeClicked = () => {
    window.location.hash = '#'
  }
  const onGoBack = () => {
    window.location.hash = '#/lenslist'
  }

  const homeClasses = classNames({
    [Style.home]: true,
  })

  if (lensId && !lens)
    return (
      <Page footer>
        <Container style={{ display: 'flex' }}>
          <div className={Style.formContainer}>
            <Loader label='Loading Lens'></Loader>
          </div>
        </Container>
      </Page>
    )

  return (
    <Page footer>
      <Container>
        <div className={Style.formContainer}>
          <Icon type='home' size='36px' className={homeClasses} onClick={onHomeClicked} />
          <LensForm lens={lens} onGoBack={onGoBack} />
        </div>
      </Container>
    </Page>
  )
}

export default Lens
