import { TokenExpiryWrapper } from './TokenManager'
import { getAsync, postAsync, putAsync } from '../helpers/apiRequest'
import { IResponse } from '../models/api/IResponse'
import { IMapFormData, IMapRequestModel } from '../models/api/IMapRequestModel'
import { IMap } from '../models/IMapModel'

const apiUrl = '/map'

export const getRevisionMaps = TokenExpiryWrapper(
  (request: IMapRequestModel): Promise<IResponse<IMap[]>> => getAsync(`${apiUrl}/${request.revisionId}`, request),
  [],
  null,
)

export const saveMap = TokenExpiryWrapper(
  async (request: IMapFormData): Promise<IResponse<IMap>> => {
    if (request.id && request.id > 0) return putAsync(apiUrl, request)
    else return postAsync(apiUrl, request)
  },
  [],
  null,
)
