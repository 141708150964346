import { Dropdown, IDropdownItemProps, Loader } from '@aurecon-creative-technologies/styleguide'
import { FC, useEffect, useMemo, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { ILens } from '../../models/ILensModel'
import { LensLookup } from '../../stores/AppStore'

import Style from '../../styles/LensDropdown.module.sass'

interface ILensDropdownProps {
  lensId?: number
  onSelected: (lensId: number) => void
}

const LensDropdown: FC<ILensDropdownProps> = (props) => {
  const { lensId, onSelected } = props
  const loadableLenses = useRecoilValueLoadable(LensLookup)
  const [loading, setLoading] = useState(true)

  const [lenses, setLenses] = useState<ILens[]>()

  useEffect(() => {
    if (loadableLenses.state !== 'hasValue' || !loadableLenses.contents) {
      setLoading(true)
      return
    }
    setLenses(loadableLenses.contents.map((lookup) => ({ ...lookup })))
    setLoading(false)
  }, [loadableLenses.contents, loadableLenses.state])

  const onSelectItem = (id: string | number) => {
    onSelected && onSelected(Number(id))
  }
  const lensList: IDropdownItemProps[] = useMemo(() => {
    if (!lenses || !lenses.length) return []
    return lenses.map((lens) => {
      return {
        id: lens.id,
        label: (
          <div className={Style.dropdownItem}>
            {lens.blobUrl?.length ? (
              <img src={lens.blobUrl} width={32} height={32}></img>
            ) : (
              <div style={{ border: '1px solid #ccc', width: '32px', height: '32px' }}></div>
            )}
            <div>{lens.title}</div>
          </div>
        ),
      } as IDropdownItemProps
    })
  }, [lenses])

  return loading ? (
    <div className={Style.loader}>
      <Loader size='extra small' />
      <div className={Style.message}>loading lenses</div>
    </div>
  ) : (
    <Dropdown items={lensList} selectedItem={lensId} onSelectItem={onSelectItem} cssClass={Style.dropdown}></Dropdown>
  )
}

export default LensDropdown
