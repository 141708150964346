import { Button, Grid, Pill, useToast } from '@aurecon-creative-technologies/styleguide'
import { FC, useEffect, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import { ILookup } from '../../models/api/ILookup'
import { DeltamapDetails, RevisionMaps, TechnologyLookup } from '../../stores/AppStore'
import ConfirmModal from '../common/ConfirmModal'
import LookupBox from '../common/LookupBox'
import LoadingScreen from '../LoadingScreen'
import { IRevisionTechnology } from '../../models/IRevisionTechnologyModel'
import { IRevisionTechnologyModel } from '../../models/api/IRevisionTechnologyRequestModel'
import { saveRevisionTechnology } from '../../api/RevisionTechnologyService'

import Style from './../../styles/Technologies.module.sass'

interface ITechnologiesProps {
  revisionId: number
  revisionTechnologies: IRevisionTechnology[] | null
  onDirty: (dirty: boolean) => void
}

const Technologies: FC<ITechnologiesProps> = (props) => {
  const { revisionTechnologies, revisionId, onDirty } = props
  const { addToast } = useToast()

  const loadableTechnologies = useRecoilValueLoadable(TechnologyLookup)
  const refreshDeltamap = useRecoilRefresher_UNSTABLE(DeltamapDetails)
  const refreshTechnologies = useRecoilRefresher_UNSTABLE(TechnologyLookup)
  const refreshRevisionMaps = useRecoilRefresher_UNSTABLE(RevisionMaps)

  const [technologies, setTechnologies] = useState<ILookup[]>()
  const [revTechs, setRevTechs] = useState<IRevisionTechnologyModel[]>()
  const [saving, setSaving] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [formDirty, setFormDirty] = useState(false)

  useEffect(() => {
    if (revTechs) return

    setRevTechs(revisionTechnologies?.map((rt) => ({ ...rt })) || undefined)
  }, [revTechs, revisionTechnologies])

  useEffect(() => {
    if (loadableTechnologies.state !== 'hasValue' || !loadableTechnologies.contents || technologies) return
    setTechnologies(loadableTechnologies.contents.map((lookup) => ({ ...lookup })))
  }, [loadableTechnologies.contents, loadableTechnologies.state, technologies])

  const addData = (data: ILookup) => {
    if (data.id === -1) {
      // add a new record
      setTechnologies((current) =>
        current ? [...current, { id: (data.id = -100 - current.length), title: data.title }] : undefined,
      )
    }
    if (!revTechs) return
    const deleting = revTechs.find((d) => d.technologyId === data.id && d.isDeleting)
    if (deleting) {
      deleting.isDeleting = false // restore existing item
      setRevTechs((current) => current && [...current.filter((d) => d.technologyId !== data.id), deleting])
    } else {
      setRevTechs((current) =>
        current ? [...current, { revisionId, technologyId: data.id, technologyTitle: data.title }] : undefined,
      )
    }
    setFormDirty(true)
    onDirty && onDirty(true)
  }

  const removeData = (techId: number) => {
    if (!revTechs) return
    const deleting = revTechs.find((d) => d.technologyId === techId)
    if (deleting) {
      deleting.isDeleting = true
      setRevTechs((current) => current && [...current.filter((d) => d.technologyId !== techId), deleting])
    }
    setFormDirty(true)
    onDirty && onDirty(true)
  }

  const onSave = async () => {
    if (!revTechs) return
    setSaving(true)

    const response = await saveRevisionTechnology({
      data: revTechs,
    })
    setSaving(false)

    if (!response || (response && response.success === false)) {
      addToast({
        type: 'error',
        message: 'Technologies can not be updated. Please try again later',
        timeout: 5000,
      })
      return
    }

    refreshDeltamap()
    refreshTechnologies()
    refreshRevisionMaps()

    addToast({
      type: 'success',
      message: `Technologies has been successfully updated`,
      timeout: 5000,
    })
    setFormDirty(false)
    onDirty && onDirty(false)
  }

  const onCancel = () => {
    // cleanup
    setTechnologies(undefined)
    setRevTechs(undefined)
    setFormDirty(false)
    setOpenModal(false)
    onDirty && onDirty(false)
  }

  return (
    <div className={Style.mapDataContainer}>
      <Grid row>
        <Grid cell item xs={12}>
          <h2>Technology Selection</h2>
        </Grid>
      </Grid>

      <Grid row cssClass={Style.dataList}>
        <Grid item xs={12}>
          <p className={Style.dataListTitle}>Technologies Selected ({revisionTechnologies?.length})</p>
        </Grid>
        <Grid item xs={12} cssClass={Style.technologyWrapper}>
          {technologies && revTechs ? (
            revTechs
              ?.filter((d) => !d.isDeleting)
              .map((data) => (
                <Pill key={data.technologyId} onClose={() => removeData(data.technologyId)} colour={1}>
                  {technologies.find((t) => t.id === data.technologyId)?.title || 'unknown'}
                </Pill>
              ))
          ) : (
            <LoadingScreen />
          )}
        </Grid>
        <Grid item xs={8}>
          <div className={Style.actionButtons}>
            <LookupBox
              lookupItems={
                technologies?.filter((t) => !revTechs?.some((d) => d.technologyId === t.id && !d.isDeleting)) || []
              }
              onSelectedItem={(item) => addData(item)}
              allowNewItem
              minCharsBeforeOpen={0}
              cssClass={Style.fixed}
              placeholder='Enter Technology Name Here'
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={Style.actionButtons}>
            <Button
              label='Cancel'
              type='secondary'
              disabled={saving}
              onClick={() => {
                !formDirty && onCancel()
                setOpenModal(true)
              }}
            />
            <Button label='Save' type='primary' disabled={!formDirty || saving} onClick={onSave} loading={saving} />
          </div>
        </Grid>
      </Grid>
      <ConfirmModal
        open={openModal}
        onYes={onCancel}
        onClose={() => setOpenModal(false)}
        title={`Cancel Editing Technologies?`}
        message={`Canceling can not be undone, all the data within the task will lost.`}
      />
    </div>
  )
}

export default Technologies
