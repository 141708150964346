import { FC, useEffect, useMemo, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import {
  FormInput,
  Dropdown,
  Button,
  Switcher,
  Pagination,
  Container,
  Grid,
  Icon,
} from '@aurecon-creative-technologies/styleguide'

import LoadingScreen from '../components/LoadingScreen'
import Page from '../components/Page'

import { getDeltamaps } from '../api/DeltamapService'
import {
  filterOptions,
  FilterOptionsEnum,
  DeltamapListSortOrder,
  sortOptions,
  SortOptionsEnum,
} from '../enums/DeltamapEnum'
import { IDeltamap } from '../models/IDeltamapModel'
import DeltamapListView from '../components/deltamap/DeltamapListView'
import DeltamapCardView from '../components/deltamap/DeltamapCardView'

import { ReactComponent as SortDescending } from '../assets/sort_descending_icon.svg'
import { ReactComponent as SortAscending } from '../assets/sort_ascending_icon.svg'
import { appInsights } from '../api/AppInsights'

import { ResponseData } from '../models/api/IResponse'
import { CurrentDeltamapId, CurrentRevisionId, NewDeltamapId } from '../stores/AppStore'
import { useAuth0 } from '@auth0/auth0-react'

import Style from '../styles/DeltamapList.module.sass'

const PageView = {
  Cards: '1',
  List: '2',
}

const DeltamapList: FC = () => {
  const [searchText, setSearchText] = useState('')
  const [selectedFilter, setSelectedFilter] = useState<string | number>(FilterOptionsEnum.AllStatuses.toString())
  const [sortOption, setSortOption] = useState<string | number>(SortOptionsEnum.DeltamapModifiedDate.toString())
  const [deltamaps, setDeltamaps] = useState<IDeltamap[] | null>([])
  const [descending, setDescending] = useState(DeltamapListSortOrder.asc)
  const [loading, setLoading] = useState(true)
  const [deltamapView, setDeltamapView] = useState(PageView.Cards)
  const [page, setPage] = useState(1)
  const [newDeltamapId] = useRecoilState(NewDeltamapId)
  const setCurrentDeltamapId = useSetRecoilState(CurrentDeltamapId)
  const setCurrentRevisionId = useSetRecoilState(CurrentRevisionId)

  const { logout } = useAuth0()

  const handleLogout = () => {
    const returnTo = `${window.location.origin}/#/login`
    logout({ returnTo })
  }

  useEffect(() => {
    setCurrentDeltamapId(null)
    setCurrentRevisionId(null)

    const getDeltamapAsync = async () => {
      const deltamapList = ResponseData(await getDeltamaps({}))
      setDeltamaps(deltamapList)

      setLoading(false)
    }
    if (appInsights) appInsights.trackPageView({ name: 'DeltamapList' })

    getDeltamapAsync()
    return () => setDeltamaps([])
  }, [setCurrentDeltamapId, setCurrentRevisionId])

  const itemsPerPage = deltamapView === PageView.Cards ? 8 : 15
  const skipItems = (page - 1) * itemsPerPage
  const cardsPerPage = page * itemsPerPage

  const searchedDeltamaps = useMemo(() => {
    if (!searchText || !deltamaps) return deltamaps || []

    return deltamaps.filter((deltamap) => {
      const matchesDeltamapName = deltamap.title.toLowerCase().includes(searchText.toLowerCase())

      return matchesDeltamapName
    })
  }, [deltamaps, searchText])

  const filteredDeltamaps = useMemo(() => {
    if (selectedFilter === FilterOptionsEnum.AllStatuses.toString() || !selectedFilter) return searchedDeltamaps

    return searchedDeltamaps.filter((deltamap) => `${deltamap.revisionStatus}` === selectedFilter)
  }, [searchedDeltamaps, selectedFilter])

  const sortedDeltamaps = useMemo(() => {
    switch (sortOption) {
      case SortOptionsEnum.DeltamapName.toString():
        return filteredDeltamaps.sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? descending : -descending,
        )

      case SortOptionsEnum.DeltamapStatus.toString():
        return filteredDeltamaps.sort((a, b) => (a.revisionStatus > b.revisionStatus ? descending : -descending))

      case SortOptionsEnum.DeltamapCreatedDate.toString():
        return filteredDeltamaps.sort((a, b) => (a.createdAt > b.createdAt ? descending : -descending))

      case SortOptionsEnum.DeltamapModifiedDate.toString():
        return filteredDeltamaps.sort((a, b) => (a.revisionUpdated > b.revisionUpdated ? descending : -descending))

      default:
        return filteredDeltamaps
    }
  }, [descending, filteredDeltamaps, sortOption])

  const onViewChange = (view: string | number) => {
    setDeltamapView(`${view}`)
  }

  const onSorting = () => {
    setDescending(-descending)
  }
  const onLensClick = () => {
    window.location.hash = '/lenslist'
  }

  const noDeltamaps =
    !sortedDeltamaps.length &&
    !searchText &&
    (selectedFilter === FilterOptionsEnum.AllStatuses.toString() || !selectedFilter)

  if (loading)
    return (
      <Page footer>
        <div className={`${Style.deltamaps}`}>
          <Container cssClass={!sortedDeltamaps.length ? Style.messageContainer : ''}>
            <h2>My Deltamaps</h2>
            <LoadingScreen text='Loading deltamap list...' />
          </Container>
        </div>
      </Page>
    )

  return (
    <Page footer>
      <Button type='primary' label='Logout' cssClass={Style.logoutButton} onClick={handleLogout} />

      <div className={`${Style.deltamaps}`}>
        <Container cssClass={!sortedDeltamaps.length ? Style.messageContainer : ''}>
          <h2>My Deltamaps</h2>

          <Grid row gap={12} cssClass={Style.toolBox}>
            <div className={Style.flexItem}>
              <Button type='primary' label='Create New' onClick={() => (window.location.hash = '/deltamap')} />
            </div>
            <div className={Style.flexItem}>
              <FormInput
                type='search'
                onChange={setSearchText}
                value={searchText}
                placeholder='Search by Name, ID...'
                disabled={noDeltamaps}
              />
            </div>
            <div className={`${Style.flexItem} ${Style.dropdown}`}>
              <span className={Style.label}>Filter</span>
              <Dropdown
                items={filterOptions}
                selectedItem={selectedFilter}
                disabled={noDeltamaps}
                onSelectItem={setSelectedFilter}
              />
            </div>
            <div className={`${Style.flexItem} ${Style.dropdown}`}>
              <span className={Style.label}>Sort</span>
              <Dropdown
                items={sortOptions}
                selectedItem={sortOption}
                disabled={noDeltamaps}
                onSelectItem={setSortOption}
              />
            </div>
            <div className={Style.flexItem}>
              <button className={Style.sortButton} disabled={noDeltamaps} onClick={onSorting}>
                {descending === DeltamapListSortOrder.desc ? <SortDescending /> : <SortAscending />}
              </button>
            </div>
            <div className={Style.viewSwitcher}>
              <Switcher
                items={[
                  { id: PageView.Cards, icon: 'apps', title: 'Tile View' },
                  { id: PageView.List, icon: 'list', title: 'List View' },
                ]}
                onSelectItem={onViewChange}
                selectedItem={deltamapView}
              />
            </div>
            <div>
              <Icon type='camera' onClick={onLensClick} className={Style.button} />
            </div>
          </Grid>

          {deltamapView === PageView.Cards && (
            <DeltamapCardView
              newDeltamapId={newDeltamapId}
              deltamaps={sortedDeltamaps}
              noDeltamaps={noDeltamaps}
              noData={deltamaps === null}
              skipItems={skipItems}
              cardsPerPage={cardsPerPage}
            />
          )}
          {deltamapView === PageView.List && (
            <DeltamapListView
              deltamaps={sortedDeltamaps}
              noDeltamaps={noDeltamaps}
              noData={deltamaps === null}
              skipItems={skipItems}
              cardsPerPage={cardsPerPage}
            />
          )}

          {!!sortedDeltamaps.length && (
            <div className={Style.pagination}>
              <Pagination
                page={page}
                pageCount={Math.ceil(sortedDeltamaps.length / itemsPerPage) || 1}
                onChange={setPage}
              />
              <div className={Style.paginationFlexGrow}>
                {skipItems + 1} - {Math.min(cardsPerPage, sortedDeltamaps.length)} of {sortedDeltamaps.length} Deltamaps
              </div>
            </div>
          )}
        </Container>
      </div>
    </Page>
  )
}

export default DeltamapList
