import { FC } from 'react'

interface IInputLabelProps {
  required?: boolean
  label: string
}

const InputLabel: FC<IInputLabelProps> = (props) => {
  return (
    <div className='field-wrapper'>
      <label>
        <span className='input-label'>
          {props.label} {props.required && <span className='input-required'>*</span>}
        </span>
      </label>
    </div>
  )
}

export default InputLabel
