import { validateAlphaNumeric } from './utils'

const requiredAndAlphaNumeric = (label: string, value: string) => {
  if (!value) return `${label} is required.`
  if (value != value.trim()) return `${label} cannot start or end with whitespace.`
  if (!validateAlphaNumeric(value)) return `${label} is not an alphanumeric value.`
  return ''
}

const requiredAndNumber = (label: string, value: string) => {
  if (!value) return `${label} is required.`
  if (isNaN(Number(value))) return `${label} must be a number.`
  return ''
}

const required = (label: string, value: string) => {
  if (!value) return `${label} is required.`
  return ''
}

export const DeltamapFields = {
  deltamapId: 'deltamapId',
  title: 'title',
  user: 'user',
  tag: 'tag',
  clientId: 'clientId',
  marketId: 'marketId',
  landscapeId: 'landscapeId',
  lensId: 'lensId',
}

export const DeltamapValidator = {
  [DeltamapFields.title]: (value: string): string => requiredAndAlphaNumeric('Deltamap Name', value),
  [DeltamapFields.clientId]: (value: string): string => requiredAndNumber('Client ID', value),
  [DeltamapFields.marketId]: (value: string): string => requiredAndNumber('Market ID', value),
  [DeltamapFields.landscapeId]: (value: string): string => requiredAndNumber('Landscape ID', value),
  [DeltamapFields.lensId]: (value: string): string => requiredAndNumber('Lens ID', value),
}

export const LensFields = {
  lensId: 'lensId',
  title: 'title',
  fileName: 'fileName',
  blobName: 'fileName',
}

export const LensValidator = {
  [LensFields.title]: (value: string): string => requiredAndAlphaNumeric('Lens Name', value),
  [LensFields.fileName]: (value: string): string => required('Filename', value),
}

export const MapFields = {
  title: 'title',
  lensId: 'lensId',
}
export const MapValidator = {
  [DeltamapFields.title]: (value: string): string => requiredAndAlphaNumeric('Map Name', value),
  [DeltamapFields.lensId]: (value: string): string => requiredAndNumber('Lens ID', value),
}
