import { Button, Dropdown, Grid, useToast } from '@aurecon-creative-technologies/styleguide'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import { IMapDataModel } from '../../models/api/IMapDataRequestModel'
import { ILookup } from '../../models/api/ILookup'
import { LensLookup, RevisionMaps, TechnologyLookup } from '../../stores/AppStore'
import { Stage, Layer, Group, Circle, Text } from 'react-konva'

import { KonvaEventObject } from 'konva/lib/Node'
import { Vector2d } from 'konva/lib/types'
import ConfirmModal from '../common/ConfirmModal'
import { point, useOffset } from '../hooks/useOffset'
import LensImage from './LensImage'
import { ILens } from '../../models/ILensModel'
import { IDeltamap } from '../../models/IDeltamapModel'
import LensDropdown from '../common/LensDropdown'
import InputLabel from '../common/InputLabel'
import { IMapCreate } from '../../models/IMapModel'
import { saveMap } from '../../api/MapService'
import TextBox from '../common/TextBox'
import { MapFields, MapValidator } from '../../helpers/deltamapFormValidator'
import { useDrawGridlines } from '../hooks/useDrawGridlines'

import Style from './../../styles/Visualise.module.sass'

interface IVisualiseProps {
  deltaMap: IDeltamap
  onDirty: (dirty: boolean) => void
}

const circleRadius = 20
const listTop = circleRadius * 3

const Visualise: FC<IVisualiseProps> = (props) => {
  const { deltaMap, onDirty } = props
  const { addToast } = useToast()
  const loadableTechnologies = useRecoilValueLoadable(TechnologyLookup)
  const loadableLenses = useRecoilValueLoadable(LensLookup)
  const loadableMaps = useRecoilValueLoadable(RevisionMaps)
  const [technologies, setTechnologies] = useState<ILookup[]>()
  const [lenses, setLenses] = useState<ILens[]>()
  const [lens, setLens] = useState<ILens>()
  const [dmData, setDmData] = useState<IMapDataModel[]>()

  const [maps, setMaps] = useState<IMapCreate[]>()
  const [map, setMap] = useState<IMapCreate>()
  const [errors, setErrors] = useState({})

  const [saving, setSaving] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [formDirty, setFormDirty] = useState(false)
  const refreshRevisionMaps = useRecoilRefresher_UNSTABLE(RevisionMaps)

  const outputRef = useRef<HTMLDivElement>(null)

  const [offset, percentToPosition, positionToPercent] = useOffset(outputRef)

  const gridlines = useDrawGridlines(offset)

  useEffect(() => {
    if (dmData || !technologies || !map?.mapDatas) return
    if (map.mapDatas) {
      setDmData(
        map.mapDatas.map((d) => ({
          ...d,
          technologyTitle: technologies.find((t) => t.id === d.technologyId)?.title || 'unknown',
          isDragging: false,
        })),
      )
    } else setDmData(map.mapDatas || undefined)
  }, [dmData, map, map?.mapDatas, technologies])

  useEffect(() => {
    if (loadableTechnologies.state !== 'hasValue' || !loadableTechnologies.contents) return
    setTechnologies(loadableTechnologies.contents.map((lookup) => ({ ...lookup })))
  }, [loadableTechnologies.contents, loadableTechnologies.state])

  useEffect(() => {
    if (loadableLenses.state !== 'hasValue' || !loadableLenses.contents) return
    setLenses(loadableLenses.contents.map((lookup) => ({ ...lookup })))
    setLens(loadableLenses.contents.find((l) => l.id === deltaMap.lensId))
  }, [deltaMap.lensId, loadableLenses.contents, loadableLenses.state])

  useEffect(() => {
    if (loadableMaps.state !== 'hasValue' || !loadableMaps.contents) return
    setMaps(loadableMaps.contents.map((lookup) => ({ ...lookup })))
    if (loadableMaps.contents.length > 0) {
      setMap(loadableMaps.contents[0])
    }
    setDmData(undefined)
  }, [deltaMap.lensId, loadableMaps.contents, loadableMaps.state])

  const onLensSelected = (id: number) => {
    setLens(lenses?.find((l) => l.id === id))
  }

  const validateForm = (newMap: IMapCreate) => {
    const errorMessage = { ...errors }
    Object.keys(MapValidator).forEach((key) => (errorMessage[key] = MapValidator[key](newMap[key])))
    return errorMessage
  }

  const onMapTitleChanged = (value: string) => {
    if (!map) return
    const newMap = { ...map, title: value }
    setMap(newMap)
    setErrors({ ...validateForm(newMap) })
    setFormDirty(true)
    onDirty && onDirty(true)
  }

  const onMapSelected = (id: number) => {
    const found = maps?.find((m) => m.id === id)
    setMap(found)
    setDmData(undefined)
  }
  const onNewMapClick = () => {
    const newMap: IMapCreate = {
      id: -1,
      revisionId: deltaMap.revisionId,
      calculated: false,
      title: 'New Map',
      mapDatas: deltaMap.revisionTechnologies.map((rt) => ({ ...rt, mapId: -1, x: -1, y: -1 })),
    }
    setMaps((current) => (!current ? [newMap] : [...current, newMap]))
    setMap(newMap)
    setDmData(undefined)
  }

  const onSave = async () => {
    if (!dmData) return
    setSaving(true)

    const response = await saveMap({ ...map, mapDatas: dmData, lensId: lens?.id || deltaMap.lensId })
    setSaving(false)

    if (!response || (response && response.success === false)) {
      addToast({
        type: 'error',
        message: 'Map can not be updated. Please try again later',
        timeout: 5000,
      })
      return
    }
    refreshRevisionMaps()

    addToast({
      type: 'success',
      message: `Map has been successfully updated`,
      timeout: 5000,
    })
    setMap(undefined)
    setDmData(undefined)
    setFormDirty(false)
    onDirty && onDirty(false)
  }

  const onCancel = () => {
    // cleanup
    setDmData(undefined)
    setFormDirty(false)
    setOpenModal(false)
    onDirty && onDirty(false)
  }

  const handleDragStart = (e: KonvaEventObject<DragEvent>) => {
    const id = e.target.id()
    setDmData((data) =>
      data?.map((d) => {
        return {
          ...d,
          isDragging: d.technologyId.toString() === id,
        }
      }),
    )
  }
  const handleDragEnd = (e: KonvaEventObject<DragEvent>) => {
    const id = e.target.id()
    setDmData((data) =>
      data?.map((d) => {
        const percent = d.technologyId.toString() === id ? positionToPercent({ x: e.target.x(), y: e.target.y() }) : d
        return {
          ...d,
          x: percent.x,
          y: percent.y,
          isDragging: false,
        }
      }),
    )
    setFormDirty(true)
    onDirty && onDirty(true)
  }
  const dragBoundFunc = (pos: Vector2d) => {
    pos.x = pos.x > offset.stageWidth - offset.padding ? offset.stageWidth - offset.padding : pos.x
    pos.y = pos.y > offset.stageHeight - offset.padding ? offset.stageHeight - offset.padding : pos.y
    pos.x = pos.x < 50 ? 50 : pos.x
    pos.y = pos.y < 50 ? 50 : pos.y
    return pos
  }

  const createDataPoint = (data: IMapDataModel, point: point, idx: number, isPlaceholder?: boolean) => {
    const id = isPlaceholder ? `ph${data.technologyId.toString()}` : data.technologyId.toString()
    return (
      <Group
        key={`group${id}`}
        id={id}
        x={point.x}
        y={point.y}
        draggable={!isPlaceholder}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        scaleX={data.isDragging && !isPlaceholder ? 1.2 : 1}
        scaleY={data.isDragging && !isPlaceholder ? 1.2 : 1}
        dragBoundFunc={dragBoundFunc}
      >
        <Circle
          text={data.technologyTitle}
          key={`circle${id}`}
          id={id}
          x={0}
          y={circleRadius / 2}
          fill='#414BB2'
          radius={circleRadius}
          opacity={isPlaceholder ? 0.2 : 0.8}
        ></Circle>
        <Text
          text={`${idx + 1}`}
          fontSize={18}
          fontStyle={'bold'}
          align='center'
          verticalAlign='middle'
          key={`number${id}`}
          id={id}
          x={-circleRadius}
          y={-circleRadius / 2}
          height={circleRadius * 2}
          width={circleRadius * 2}
          fill='white'
          opacity={1}
        ></Text>

        <Text
          text={data.technologyTitle}
          key={`text${id}`}
          id={id}
          x={circleRadius + 5}
          y={-circleRadius / 2}
          height={circleRadius * 2}
          verticalAlign='middle'
          fill='black'
          opacity={isPlaceholder ? 0.2 : 0.8}
        ></Text>
      </Group>
    )
  }

  const drawDataPoints = useCallback(() => {
    const points: JSX.Element[] = []

    dmData?.forEach((d, idx) => {
      points.push(createDataPoint(d, { x: circleRadius * 2, y: idx * circleRadius * 3 + listTop }, idx, true))
      if (d.x <= 0 || d.y <= 0) {
        points.push(createDataPoint(d, { x: circleRadius * 2, y: idx * circleRadius * 3 + listTop }, idx, false))
      } else {
        const point = percentToPosition(d)
        points.push(createDataPoint(d, point, idx))
      }
    })
    return points
  }, [dmData]) // eslint-disable-line

  return (
    <div className={Style.visualiseContainer}>
      <Grid row cssClass={Style.gridRow}>
        <Grid cell item xs={2}>
          <h2>Visualise</h2>
        </Grid>
        <Grid cell item xs={2}>
          <InputLabel label='Show Map' />
          <Dropdown
            items={
              maps?.map((m) => ({
                id: m.id || 0,
                label: m.title || '',
                icon: m.calculated ? 'functions' : 'person',
              })) || []
            }
            onSelectItem={(id) => onMapSelected(Number(id))}
            selectedItem={map?.id}
            disabled={formDirty}
          />
        </Grid>
        <Grid cell item xs={1} style={{ justifyContent: 'flex-end' }}>
          <Button label='New Map' onClick={onNewMapClick} style={{ margin: '0 12px' }} disabled={formDirty} />
        </Grid>
        <Grid cell item xs={2} style={{ padding: '0px 12px' }}>
          <TextBox
            label='Map Name'
            placeholder='Enter map Name here'
            error={errors[MapFields.title]}
            required={true}
            value={map?.title || ''}
            onChange={(value: string) => onMapTitleChanged(value)}
            //limit={100}
          />
        </Grid>
        <Grid cell item xs={2}>
          <InputLabel label='Show Lens' />
          <LensDropdown lensId={lens?.id ?? deltaMap?.lensId} onSelected={onLensSelected} />
        </Grid>
      </Grid>
      <div ref={outputRef} className={Style.stageHolder}>
        <Stage width={offset.stageWidth} height={offset.stageHeight} className={Style.stage}>
          {lens && (
            <Layer>
              <LensImage key={lens.id} lens={lens} opacity={lens.opacity || 50} offset={offset} />
            </Layer>
          )}
          <Layer>{gridlines}</Layer>
          <Layer>{drawDataPoints()}</Layer>
        </Stage>
      </div>
      <ConfirmModal
        open={openModal}
        onYes={onCancel}
        onClose={() => setOpenModal(false)}
        title={`Cancel Editing Visualisation?`}
        message={`Canceling can not be undone, all the data within the task will lost.`}
      />
      <Grid row cssClass={Style.gridBottomRow}>
        <Grid item xs={4}>
          <div className={Style.actionButtons}>
            <Button
              label='Cancel'
              type='secondary'
              disabled={saving}
              onClick={() => {
                !formDirty && onCancel()
                setOpenModal(true)
              }}
            />
            <Button label='Save' type='primary' disabled={!formDirty || saving} onClick={onSave} loading={saving} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Visualise
