import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Loader } from '@aurecon-creative-technologies/styleguide'

import Style from '../../styles/SuggestionBox.module.sass'
import TextBox from './TextBox'
import { ISuggestionItem } from '../../models/ISuggestionItem'
import classNames from 'classnames'

interface ISuggestionBox {
  label?: string
  required?: boolean
  error?: string
  disabled?: boolean
  placeholder?: string
  limit?: number
  selectedItem?: string | null
  loading?: boolean
  displaySelectedItem?: boolean
  open: boolean
  searching: boolean
  suggestionItems: ISuggestionItem[]
  allowNewItem?: boolean
  modal?: boolean
  cssClass?: string
  minCharsBeforeOpen?: number
  validate?: (text: string) => string
  onBlur?: () => void
  onFocus?: () => void
  onClose?: () => void
  onSearch: (searchText: string) => void
  onSelectedItem: (item: ISuggestionItem) => void
}

const SuggestionBox: FC<ISuggestionBox> = (props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [searchText, setSearchText] = useState<string>()
  const [openResults, setOpenResults] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    label,
    required,
    disabled,
    placeholder,
    error,
    limit,
    selectedItem,
    open,
    searching,
    loading,
    suggestionItems,
    displaySelectedItem,
    allowNewItem,
    cssClass,
    modal,
    minCharsBeforeOpen = 3,
    onBlur,
    onFocus,
    onClose,
    onSearch,
    onSelectedItem,
    validate,
  } = props

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleWindowClick = (event: any) => {
      if (ref.current) {
        const currentState = ref.current
        if ((ref && currentState.contains(event.target)) || event.target.parentNode === null) return
        setOpenResults(false)
        onClose && onClose()
      }
    }

    window.addEventListener('click', handleWindowClick)
    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  }, [onClose])

  useEffect(() => {
    setOpenResults(open)
  }, [open])

  useEffect(() => {
    if (!selectedItem) return

    setSearchText(selectedItem)
  }, [selectedItem])

  useEffect(() => {
    setErrorMessage(error || '')
  }, [error])

  const onSelected = useCallback(
    (item: ISuggestionItem) => {
      onSelectedItem(item)
      setOpenResults(false)
      setSearchText(displaySelectedItem ? item.label : '')
      !displaySelectedItem && ref.current?.getElementsByTagName('input').item(0)?.focus()
    },
    [displaySelectedItem, onSelectedItem, setSearchText, setOpenResults],
  )

  const onSearchFocus = () => {
    displaySelectedItem && ref.current?.getElementsByTagName('input').item(0)?.select()
    onFocus && onFocus()
  }
  const onChanged = (value: string) => {
    setOpenResults(value.length >= minCharsBeforeOpen)
    setSearchText(value)
    onSearch(value)
    validate && setErrorMessage(validate(value))
  }

  const onKeyDown = (value: string) => {
    value === 'Tab' && setOpenResults(false)
  }

  const searchResults = useMemo(() => {
    if (!openResults) return null

    const noResultsMessage = () => {
      return allowNewItem && searchText && !errorMessage ? (
        <div className={Style.item} onClick={() => onSelected({ id: searchText, value: searchText })}>
          Add "{searchText}"
        </div>
      ) : (
        <div className={Style.notFound}>No results found.</div>
      )
    }

    if (searching)
      return (
        <div
          className={classNames({
            [Style.limit]: !!limit,
            [Style.list]: true,
            [Style.modal]: modal,
            [cssClass || '']: !!cssClass,
          })}
        >
          <div className={Style.searchLoader}>
            <Loader size='extra small' />
          </div>
        </div>
      )

    return (
      <div
        className={classNames({
          [Style.limit]: !!limit,
          [Style.list]: true,
          [Style.modal]: modal,
          [cssClass || '']: !!cssClass,
        })}
      >
        {suggestionItems.length === 0
          ? noResultsMessage()
          : suggestionItems.map((res) => (
              <div key={res.id} className={Style.item} onClick={() => onSelected(res)}>
                {res.label || res.value}
              </div>
            ))}
      </div>
    )
  }, [
    suggestionItems,
    searching,
    openResults,
    limit,
    searchText,
    allowNewItem,
    errorMessage,
    cssClass,
    modal,
    onSelected,
  ])

  return (
    <div ref={ref} className={Style.suggestionBox}>
      <TextBox
        label={label}
        placeholder={placeholder}
        value={searchText || ''}
        onChange={onChanged}
        onBlur={onBlur}
        onFocus={onSearchFocus}
        required={required}
        disabled={disabled}
        limit={limit}
        loading={loading}
        error={errorMessage}
        onKeyDown={onKeyDown}
      ></TextBox>
      {searchResults}
    </div>
  )
}

export default SuggestionBox
