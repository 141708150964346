import { FC, useState } from 'react'
import { Button, useToast } from '@aurecon-creative-technologies/styleguide'
import { dateToString } from '../../helpers/utils'

import Style from '../../styles/LensCard.module.sass'
import { deleteLens } from '../../api/LensService'
import { useRecoilRefresher_UNSTABLE } from 'recoil'
import { LensLookup } from '../../stores/AppStore'
import ConfirmModal from '../common/ConfirmModal'

export interface ILensCardProps {
  id: number
  title: string
  updatedDate: string
  fileName: string
  thumbnail?: string
  inUse?: boolean
}

const LensCard: FC<ILensCardProps> = (props) => {
  const { id, title, updatedDate, fileName, thumbnail, inUse } = props
  const refreshLenses = useRecoilRefresher_UNSTABLE(LensLookup)
  const [openModal, setOpenModal] = useState(false)

  const { addToast } = useToast()

  const handleOpenLens = () => {
    window.location.hash = `/lens/${id}`
  }

  const handleDeleteLens = async () => {
    setOpenModal(true)
  }
  const onDeleteLens = async () => {
    setOpenModal(false)
    const result = await deleteLens({ lensId: id })
    if (result?.success && result.data)
      addToast({
        type: 'success',
        message: 'Lens has been deleted successfully',
        timeout: 5000,
      })
    else
      addToast({
        type: 'error',
        message: 'Lens can not be deleted. Please try again later',
        timeout: 5000,
      })
    refreshLenses()
  }

  return (
    <div className={Style.cardContainer}>
      <div className={Style.cardContent}>
        <div className={Style.cardTitle} title={title}>
          <span>{title}</span>
        </div>
        <div className={Style.cardThumbnail}>{thumbnail && <img src={thumbnail} width={160} height={120}></img>}</div>
        <div className={Style.cardFileName} title={title}>
          <span>{fileName}</span>
        </div>
        <div className={Style.lineWrapper}>
          <span>{dateToString(updatedDate)}</span>
        </div>
      </div>
      <div className={`${Style.actionButtons} lens-icon-button`}>
        <Button label='Open' onClick={handleOpenLens} disabled={!id} />
        <Button
          type='icon-square'
          icon='delete'
          onClick={handleDeleteLens}
          disabled={inUse}
          title={inUse ? 'Cannot delete as Lens is in use' : 'Delete Lens'}
        />
      </div>
      <ConfirmModal
        open={openModal}
        onYes={onDeleteLens}
        onClose={() => setOpenModal(false)}
        title={`Delete Lens?`}
        message={`Are you sure you want to delete this Lens?`}
      />
    </div>
  )
}

export default LensCard
