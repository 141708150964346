import { FC } from 'react'
import classnames from 'classnames'

import { ILens } from '../../models/ILensModel'
import LensCard from './LensCard'
import NoLensesMessage from './NoLensesMessage'

import { Grid } from '@aurecon-creative-technologies/styleguide'

import Style from './../../styles/LensCardView.module.sass'

interface ILensCardViewProps {
  newLensId: number | null
  lenses: ILens[]
  noLenses: boolean
  noData: boolean
  skipItems: number
  cardsPerPage: number
}

const LensCardView: FC<ILensCardViewProps> = (props) => {
  const { newLensId, lenses, noLenses, noData, skipItems, cardsPerPage } = props

  if (!lenses.length) return <NoLensesMessage noData={noData} noLenses={noLenses} />
  return (
    <Grid row gap={12}>
      {lenses.slice(skipItems, cardsPerPage).map((lens) => (
        <Grid
          key={lens.id}
          item
          xs={12}
          sm={6}
          lg={3}
          xl={3}
          cssClass={classnames({
            [Style.hightLight]: lens.id === newLensId,
          })}
        >
          <LensCard
            id={lens.id}
            title={lens.title}
            fileName={lens.fileName}
            updatedDate={lens.updatedAt || lens.createdAt || ''}
            thumbnail={lens.blobUrl}
            inUse={lens.inUse}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default LensCardView
