import { Grid } from '@aurecon-creative-technologies/styleguide'
import { FC } from 'react'

import Style from '../../styles/NoLensesMessage.module.sass'

interface INoLensesMessageProps {
  noData: boolean
  noLenses: boolean
}

const NoLensesMessage: FC<INoLensesMessageProps> = (props) => {
  const { noLenses, noData } = props

  return (
    <Grid row gap={12} cssClass={Style.messageWrapper}>
      {noData && (
        <div className={Style.message}>
          There are currently no lenses assigned to you.
          <br />
          Please contact your lens manager.
        </div>
      )}

      {noLenses && !noData && <div className={Style.message}>No lenses have been created yet.</div>}

      {!noLenses && !noData && (
        <div className={Style.message}>
          No lenses found.
          <br />
          Try adjusting your search or filter to find what you're looking for.
        </div>
      )}
    </Grid>
  )
}

export default NoLensesMessage
