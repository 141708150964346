type StatusColor = 1 | 2 | 3 | 4 | 5 | 6 | 7
interface IStatusText {
  [key: number]: string
}

export enum RevisionStatusEnum {
  InProgress = 1,
  Closed = 2,
  Shared = 3,
  Published = 4,
  Archived = 5,
}

export const RevisionStatusText: IStatusText = {
  [RevisionStatusEnum.InProgress]: 'In Progress',
  [RevisionStatusEnum.Closed]: 'Closed',
  [RevisionStatusEnum.Published]: 'Published',
  [RevisionStatusEnum.Shared]: 'Shared with others',
  [RevisionStatusEnum.Archived]: 'Archived',
}
export const RevisionStatusIcon: IStatusText = {
  [RevisionStatusEnum.InProgress]: 'schedule',
  [RevisionStatusEnum.Closed]: 'close',
  [RevisionStatusEnum.Published]: 'check_circle',
  [RevisionStatusEnum.Shared]: 'group',
  [RevisionStatusEnum.Archived]: 'inventory',
}

export const RevisionStatusVerbs: IStatusText = {
  [RevisionStatusEnum.InProgress]: 'Open',
  [RevisionStatusEnum.Closed]: 'Close',
  [RevisionStatusEnum.Published]: 'Publish',
  [RevisionStatusEnum.Shared]: 'Shared with others',
  [RevisionStatusEnum.Archived]: 'Archived',
}
export const RevisionStatusColors = {
  [RevisionStatusEnum.InProgress]: 1 as StatusColor,
  [RevisionStatusEnum.Closed]: 2 as StatusColor,
  [RevisionStatusEnum.Published]: 3 as StatusColor,
  [RevisionStatusEnum.Shared]: 4 as StatusColor,
  [RevisionStatusEnum.Archived]: 5 as StatusColor,
}
