import { FC, useState } from 'react'
import {
  Button,
  Checkbox,
  FormInput,
  IHeader,
  Loader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  useToast,
} from '@aurecon-creative-technologies/styleguide'

import { TeamMemberRoleText } from '../../enums/UserRolesEnum'
import { dateToString } from '../../helpers/utils'
import { ITeamMemberModel, ITeamMembersProps } from '../../models/ITeamMemberModel'
import { useRefreshTeamMembers } from '../../stores/AppStore'
import { activeOrDeactivedMembers } from '../../api/TeamMemberService'
import ConfirmModal from '../common/ConfirmModal'

import Style from '../../styles/TeamMembers.module.sass'

const TeamMembersDeactived: FC<ITeamMembersProps> = (props) => {
  const { addToast } = useToast()
  const [page, setPage] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [activating, setActivating] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [activeMembers, setActiveMembers] = useState<ITeamMemberModel[]>([])
  const refreshTeamMembers = useRefreshTeamMembers()
  const {
    teamMembers,
    loading,
    searchText,
    setSearchText,
    nameSort,
    handleNameSort,
    permissionsSort,
    handlePermissionsSort,
    deactivetedDateSort,
    handleDeactivetedDateSort,
  } = props

  const tableHeaders = [
    {
      label: 'Member Name',
      sort: nameSort,
      onSort: handleNameSort,
    },
    {
      label: 'Member Email',
    },
    {
      label: 'Permission',
      sort: permissionsSort,
      onSort: handlePermissionsSort,
    },
    {
      label: 'Deactived Date',
      sort: deactivetedDateSort,
      onSort: handleDeactivetedDateSort,
    },
    {
      label: '',
      checked: selectAll,
      onCheckbox: !teamMembers.length ? undefined : (checked: boolean) => onSelectAll(checked),
    },
  ] as IHeader[]

  const itemsPerPage = 10
  const skipItems = (page - 1) * itemsPerPage
  const itemPerPage = page * itemsPerPage

  if (loading) return <Loader label='Loading team members...' />

  const onSelectAll = (checked: boolean) => {
    if (!teamMembers.length) return

    const newMembers = checked ? [...teamMembers] : []
    setActiveMembers(newMembers)
    setSelectAll(checked)
  }

  const onMemberSelected = (checked: boolean, selectedMember: ITeamMemberModel) => {
    if (checked) {
      const addedMembers = [...activeMembers, selectedMember]
      setActiveMembers(addedMembers)
      setSelectAll(addedMembers.length === teamMembers.length)
      return
    }

    const index = activeMembers.findIndex(
      (member) => member.deltamapId === selectedMember.deltamapId && member.userId === selectedMember.userId,
    )

    if (index < 0) return

    const newMembers = [...activeMembers]
    newMembers.splice(index, 1)

    setActiveMembers(newMembers)
    setSelectAll(false)
  }

  const handleReactivateMembers = () => {
    setOpenModal(true)
  }

  const reactivateMembers = async () => {
    setActivating(true)
    const response = await activeOrDeactivedMembers({
      deltamapId: activeMembers[0].deltamapId,
      userIds: activeMembers.map((member) => member.userId),
      active: true,
    })

    if (!response || (response && response.success === false)) {
      addToast({
        type: 'error',
        message: `User(s) cannot be reactived. Please try again later.`,
        timeout: 5000,
      })
      return
    }

    addToast({
      type: 'success',
      message: `${activeMembers.length} User(s) has been reactived successfully.`,
      timeout: 5000,
    })

    setActivating(false)
    setActiveMembers([])
    setOpenModal(false)
    refreshTeamMembers()
  }

  const userOrUsers = activeMembers.length === 1 ? 'user' : 'users'

  return (
    <>
      <div className={Style.seachingContainer}>
        <FormInput
          type='search'
          onChange={setSearchText}
          value={searchText}
          cssClass={Style.searchBox}
          placeholder='Search by Member Name, Role,...'
        />
        <Button
          label='Reactive'
          disabled={!teamMembers.length || !activeMembers.length}
          onClick={handleReactivateMembers}
          loading={activating}
        />
      </div>
      {!!teamMembers.length && (
        <>
          <Table headers={tableHeaders} cssClass={Style.teamMembersTable}>
            {teamMembers.slice(skipItems, itemPerPage).map((member) => {
              const active = activeMembers.some(
                (activeMember) =>
                  activeMember.deltamapId === member.deltamapId && activeMember.userId === member.userId,
              )

              return (
                <TableRow key={`${member.deltamapId}_${member.userId}`} active={active}>
                  <TableCell>{member.userName}</TableCell>
                  <TableCell>{member.userEmail}</TableCell>
                  <TableCell>{TeamMemberRoleText[member.roleId]}</TableCell>
                  <TableCell>{member.deactiveDate ? dateToString(member.deactiveDate) : ''}</TableCell>
                  <TableCell>
                    <Checkbox checked={active} onChange={(checked: boolean) => onMemberSelected(checked, member)} />
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
          <div className={Style.pagination}>
            <Pagination page={page} pageCount={Math.ceil(teamMembers.length / itemsPerPage) || 1} onChange={setPage} />
          </div>
        </>
      )}
      {!teamMembers.length && (
        <div className={Style.message}>
          <p>There are no deactivated team members{searchText && ' matching your search'}.</p>
        </div>
      )}
      <ConfirmModal
        open={openModal}
        onYes={reactivateMembers}
        onClose={() => {
          setOpenModal(false)
        }}
        loading={activating}
        title={`Reactivate ${userOrUsers}?`}
        message={`This action will grant access to this Deltamap to ${activeMembers.length} ${userOrUsers}. Are you sure that you want to reactivate the selected ${userOrUsers}?`}
      />
    </>
  )
}

export default TeamMembersDeactived
