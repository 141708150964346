import { FC } from 'react'

interface IFormErrorMessage {
  message: string
}

const FormErrorMessage: FC<IFormErrorMessage> = (props) => {
  return (
    <div className='field-wrapper'>
      <label>
        <span className='input-error'>{props.message}</span>
      </label>
    </div>
  )
}

export default FormErrorMessage
