import { TokenExpiryWrapper } from './TokenManager'
import { ILensFormData, ILensRequestModel } from '../models/api/ILensRequestModel'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { getAsync, postMultiFormDataAsync, putAsync } from '../helpers/apiRequest'
import { IResponse } from '../models/api/IResponse'
import { HttpMethod } from '../enums/ApiRequestConstants'
import { ILens } from '../models/ILensModel'

const apiUrl = '/lens'

export const getLenses = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<ILens[]>> => getAsync(`${apiUrl}`, request),
  [],
  null,
)

export const getLensDetails = TokenExpiryWrapper(
  (request: ILensRequestModel): Promise<IResponse<ILens>> => getAsync(`${apiUrl}/${request.lensId}`, request),
  [],
  null,
)
export const deleteLens = TokenExpiryWrapper(
  (request: ILensRequestModel): Promise<IResponse<boolean>> => putAsync(`${apiUrl}/delete/${request.lensId}`, request),
  [],
  null,
)

export const saveLens = TokenExpiryWrapper(
  async (request: ILensFormData): Promise<IResponse<ILens>> => {
    try {
      const formData = new FormData()
      Object.keys(request)
        .filter((key) => key !== 'token')
        .forEach((key) => {
          formData.set(key, request[key] || '')
        })
      return await postMultiFormDataAsync(
        `${apiUrl}`,
        request.token || '',
        formData,
        request.id ? HttpMethod.PUT : HttpMethod.POST,
      )
    } catch (error) {
      const response = error as Response
      return {
        success: false,
        message: await response.text(),
      }
    }
  },
  [],
  null,
)
