import { FC } from 'react'
import { Button, IHeader, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'

import { IDeltamap } from '../../models/IDeltamapModel'
import { dateToString } from '../../helpers/utils'
import { RevisionStatusEnum } from '../../enums/RevisionStatusEnum'

import NoDeltamapsMessage from './NoDeltamapsMessage'

import Style from './../../styles/DeltamapListView.module.sass'

const tableHeaders = [
  {
    label: 'Deltamap Name',
  },
  {
    label: 'Client',
  },
  {
    label: 'Market',
  },
  {
    label: 'Landscape',
  },
  {
    label: 'Created Date',
  },
  {
    label: 'Action',
  },
] as IHeader[]

interface IDeltamapListViewProps {
  deltamaps: IDeltamap[]
  noDeltamaps: boolean
  noData: boolean
  skipItems: number
  cardsPerPage: number
}

const DeltamapListView: FC<IDeltamapListViewProps> = (props) => {
  const { deltamaps, noDeltamaps, noData, skipItems, cardsPerPage } = props

  if (!deltamaps.length) return <NoDeltamapsMessage noData={noData} noDeltamaps={noDeltamaps} />

  const actionButton = (deltamap: IDeltamap) => {
    switch (deltamap.revisionStatus) {
      case RevisionStatusEnum.Closed:
        return (
          <Button
            type='secondary'
            label='Preview'
            size='extra small'
            onClick={() => (window.location.hash = `/deltamap/${deltamap.id}`)}
          />
        )
      case RevisionStatusEnum.Published:
        return (
          <Button
            type='secondary'
            label='View'
            icon='open_in_new'
            size='extra small'
            cssClass={Style.viewIcon}
            onClick={() => (window.location.hash = `/deltamap/${deltamap.id}/1`)}
          />
        )

      default:
        return (
          <Button
            label='Open'
            size='extra small'
            onClick={() => (window.location.hash = `/deltamap/${deltamap.id}/4`)}
          />
        )
    }
  }

  return (
    <>
      <Table headers={tableHeaders} condensed>
        {deltamaps.slice(skipItems, cardsPerPage).map((deltamap) => {
          return (
            <TableRow key={deltamap.id}>
              <TableCell>{deltamap.title}</TableCell>
              <TableCell>{deltamap.client}</TableCell>
              <TableCell>{deltamap.market}</TableCell>
              <TableCell>{deltamap.landscape}</TableCell>
              <TableCell>{dateToString(deltamap.createdAt)}</TableCell>
              <TableCell cellClass={Style.actionColumn}>
                {actionButton(deltamap)}
                {deltamap.revisionStatus !== RevisionStatusEnum.Published && (
                  <Button
                    type='icon-square'
                    icon='settings'
                    size='extra small'
                    onClick={() => (window.location.hash = `/deltamap/${deltamap.id}/1`)}
                    cssClass={Style.settingsIcon}
                  />
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </Table>
    </>
  )
}

export default DeltamapListView
