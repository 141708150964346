import { TokenExpiryWrapper } from './TokenManager'
import { getAsync, postAsync } from '../helpers/apiRequest'
import { IResponse } from '../models/api/IResponse'
import {
  IRevisionTechnologyFormData,
  IRevisionTechnologyRequestModel,
} from '../models/api/IRevisionTechnologyRequestModel'
import { IRevisionTechnology } from '../models/IRevisionTechnologyModel'

const apiUrl = '/revisiontechnology'

export const getRevisionTechnology = TokenExpiryWrapper(
  (request: IRevisionTechnologyRequestModel): Promise<IResponse<IRevisionTechnology[]>> =>
    getAsync(`${apiUrl}/${request.revisionId}`, request),
  [],
  null,
)

export const saveRevisionTechnology = TokenExpiryWrapper(
  async (request: IRevisionTechnologyFormData): Promise<IResponse<IRevisionTechnology[]>> => postAsync(apiUrl, request),
  [],
  null,
)
