import { HttpContentType, HttpMethod } from '../enums/ApiRequestConstants'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IResponse } from '../models/api/IResponse'
import { TokenExpiryWrapper } from './TokenManager'
import { postMultiFormDataAsync } from '../helpers/apiRequest'

export const getMediaBlobUrl = async (container: string, blobName: string): Promise<string | null> => {
  try {
    const url = `/storage/${container}/${blobName}`
    const response = await fetch(url, {
      headers: { 'Content-Type': HttpContentType.TEXT },
      method: HttpMethod.GET,
    })

    if (response && response.ok) return response.text()
  } catch (error) {
    console.log('** Error fetching data...')
    console.log(error)
  }
  return null
}

export const getMediaBlob = async (container: string, blobName: string): Promise<Blob | null> => {
  try {
    const url = `/storage/download/${container}/${blobName}`
    const response = await fetch(url, {
      headers: { 'Content-Type': HttpContentType.BLOB },
      method: HttpMethod.GET,
    })

    if (response && response.ok) return response.blob()
  } catch (error) {
    console.log('** Error fetching data...')
    console.log(error)
  }
  return null
}

interface ISaveMediaRequestModel extends IBodyRequestModel {
  reportId: number
  contentFile: File
  container: string
}

export interface ISaveMediaResponseModel {
  container: string
  mediaId: string
  versionId: string
}

export const saveMedia = TokenExpiryWrapper(
  async (request: ISaveMediaRequestModel): Promise<IResponse<ISaveMediaResponseModel>> => {
    try {
      const formData = new FormData()
      Object.keys(request)
        .filter((key) => key !== 'token')
        .forEach((key) => {
          formData.append(key, request[key] || '')
        })

      return await postMultiFormDataAsync(`/storage/${request.container}`, request.token || '', formData)
    } catch (error) {
      const response = error as Response
      return {
        success: false,
        message: await response.text(),
      }
    }
  },
  [],
  null,
)
