import { useCallback, useState, useEffect, RefObject } from 'react'

export interface point {
  x: number
  y: number
}
export interface offset extends point {
  landing: number
  padding: number
  width: number
  height: number
  stageWidth: number
  stageHeight: number
}

/*

            |                                      stage width                                     |

                               
            |     landing      | pad |                        width                          | pad |


            x= -350                 x=0                                                      x=800

    y=-50   |------------------|----|--------------------------------------------------------|-----|
            |                  |    |                                                        |     |
      y=0   |------------------|----|--------------------------------------------------------|-----|
            |                  |    |                                                        |     |
            |                  |    |                                                        |     |
            |                  |    |                                                        |     |
            |                  |    |                                                        |     |
            |                  |    |                                                        |     |
            |                  |    |                                                        |     |
            |                  |    |                                                        |     |
            |                  |    |                                                        |     |
            |                  |    |                                                        |     |
            |                  |    |                                                        |     |
            |                  |    |                                                        |     |
            |                  |    |                                                        |     |
            |                  |    |                                                        |     |
            |                  |    |                                                        |     |
    y=600   |------------------|----|--------------------------------------------------------|-----|
            |                  |    |                                                        |     |
    y=650   |------------------|----|--------------------------------------------------------|-----|

*/

const preferedWidth = 800
const preferedHeight = 600
const landing = 300 // the space on the left side where the list of technologies start

const aspectRatio = preferedHeight / preferedWidth
const padding = 50 // space outside the grid for legend, etc

export const useOffset = (
  divRef: RefObject<HTMLDivElement>,
): [offset, (position: point) => point, (percent: point) => point, () => void] => {
  const [offset, setOffset] = useState<offset>({
    landing,
    padding,
    width: preferedWidth / 2,
    height: preferedHeight / 2,
    stageWidth: preferedWidth / 2 + landing + 2 * padding,
    stageHeight: preferedHeight / 2 + 2 * padding,
    x: landing + padding,
    y: padding,
  })

  const handleResize = useCallback(() => {
    if (!divRef.current) return
    let width = preferedWidth
    let height = preferedHeight
    const maxWidth = divRef.current?.clientWidth - padding * 3 - landing
    const maxHeight = divRef.current?.clientHeight - padding * 3
    if (maxWidth < maxHeight / aspectRatio) {
      width = maxWidth
      height = Math.round(maxWidth * aspectRatio)
    } else {
      height = maxHeight
      width = Math.round(maxHeight / aspectRatio)
    }

    const newOffset = {
      landing,
      padding,
      width,
      height,
      stageWidth: width + landing + 2 * padding,
      stageHeight: height + 2 * padding,
      x: landing + padding,
      y: padding,
    }
    setOffset(newOffset)
  }, [divRef])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize, divRef.current?.clientHeight, divRef.current?.clientWidth])

  const percentToPosition = (percent: point) => {
    return {
      x: Math.round(landing + padding + (percent.x * offset.width) / 100),
      y: Math.round(padding + (percent.y * offset.height) / 100),
    }
  }
  const positionToPercent = (position: point) => {
    return {
      x: Math.round(((position.x - landing - padding) / offset.width) * 100),
      y: Math.round(((position.y - padding) / offset.height) * 100),
    }
  }

  return [offset, percentToPosition, positionToPercent, handleResize]
}
