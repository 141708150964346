import { FC, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import { Icon, Loader } from '@aurecon-creative-technologies/styleguide'
import FormErrorMessage from './FormErrorMessage'

import { DocumentType, fileExtensionTypes, getExtensionFromFilename } from '../../helpers/FileUploadAdapter'
import { LENS_CONTAINER } from '../../config/config'

import Style from '../../styles/Uploader.module.sass'

interface IUploaderProps {
  accept?: string[]
  maxSize?: number
  error?: string
  fileUrl?: string
  fileName?: string
  readonly?: boolean
  multiple?: boolean
  onFileChange?: (files: File[], error?: string) => void
}

const DEFAULT_MAX_SIZE = 1024 * 1024
const MIN_SIZE = 0

const Uploader: FC<IUploaderProps> = (props) => {
  const [documentUrl, setDocumentUrl] = useState<string>()
  const [documentName, setDocumentName] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [uploading, setUploading] = useState(false)
  const [fileType, setFileType] = useState<DocumentType>(DocumentType.Unknown)

  const { onFileChange, accept, fileUrl, maxSize, multiple, readonly, error, fileName } = props
  const fileMaxSize = DEFAULT_MAX_SIZE * (maxSize || 1)

  const onFileLoaded = useCallback(
    (files: File[], fileError?: string) => onFileChange && onFileChange(files, fileError),
    [onFileChange],
  )

  const onDrop = useCallback(
    (files: File[], fileRejection: FileRejection[]) => {
      const errors = fileRejection[0]?.errors[0]
      if (errors) {
        setErrorMessage(
          errors?.code === ErrorCode.FileTooLarge
            ? `The size of file is larger than the maximum size`
            : errors?.message,
        )
      } else {
        onFileLoaded(files)
        if (files[0]) setDocumentUrl(URL.createObjectURL(files[0]))
        setDocumentName(files[0]?.name)
        setUploading(files.length > 0)
        setFileType(DocumentType.Image)
      }
    },
    [onFileLoaded],
  )

  const { getRootProps, getInputProps, inputRef, isDragActive } = useDropzone({
    onDrop,
    accept: accept ? { ['image/*']: accept } : undefined,
    maxSize: fileMaxSize,
    minSize: MIN_SIZE,
    disabled: readonly,
    multiple: multiple ?? false,
  })

  useEffect(() => {
    if (uploading && fileType === DocumentType.Document) setUploading(false)
  }, [fileType, uploading])

  useEffect(() => {
    setDocumentName(fileName || '')
  }, [fileName])

  useEffect(() => {
    if (fileUrl) setDocumentUrl(fileUrl || '')
    setErrorMessage(error || '')
  }, [fileUrl, error])

  useEffect(() => {
    const fileInput = inputRef.current
    const fileInputChange = () => setUploading(true)
    fileInput?.addEventListener('change', fileInputChange)

    return () => fileInput?.removeEventListener('change', fileInputChange)
  }, [inputRef])

  const getFileTypeByUrl = (url: string) => {
    if (url.includes(LENS_CONTAINER)) return DocumentType.Image
    return DocumentType.Unknown
  }
  useEffect(() => {
    if (documentName) {
      const ext = getExtensionFromFilename(documentName)
      setFileType(fileExtensionTypes[ext] || DocumentType.Unknown)
    } else if (fileName) {
      setFileType(getFileTypeByUrl(fileName))
    }
  }, [documentName, fileName])

  const onDeleteFile = () => {
    setDocumentUrl('')
    onFileLoaded([])
    onFileChange && onFileChange([])
  }

  const uploadPlaceHolder = () => {
    if (isDragActive) return <p>Drop the files here ...</p>

    return (
      <div className={Style.uploaderPlaceholder}>
        <>
          <Icon cssClass={Style.addIcon} colour='#c4c4c4' size='48px' type='note_add' />
          <div className={Style.title}>
            Drop files to upload or <a>browse</a>
          </div>
          <div className={Style.subtitle}>(Max file size: {maxSize}MB)</div>
        </>
      </div>
    )
  }

  const loadingStyle = { display: uploading ? 'none' : 'unset' }
  if (documentUrl) {
    return (
      <div className={Style.otherContainer}>
        {fileType === DocumentType.Image && (
          <img style={loadingStyle} src={documentUrl} alt='Uploader Preview Mode' onLoad={() => setUploading(false)} />
        )}

        {fileType === DocumentType.Video && (
          <video style={loadingStyle} onLoadedData={() => setUploading(false)} src={documentUrl} />
        )}

        {fileType === DocumentType.Document && <Icon type='description' size='58px' colour='#c4c4c4' />}

        {!uploading && (
          <span className={Style.fileNameBox}>
            <p>{fileName}</p>
            {!readonly && <Icon type='close' size='20px' cssClass={Style.deleteIcon} onClick={onDeleteFile} />}
          </span>
        )}
        {uploading && <Loader label='Uploading...' />}
      </div>
    )
  }
  return (
    <>
      <div
        className={classNames({
          [Style.otherContainer]: true,
          [Style.readonly]: readonly,
        })}
        {...getRootProps()}
      >
        <input ref={inputRef} {...getInputProps()} />
        {uploadPlaceHolder()}
      </div>
      {errorMessage && <FormErrorMessage message={errorMessage} />}
    </>
  )
}

export default Uploader
